.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }

.toast-message a,
.toast-message label {
  color: #ffffff; }

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/ }

#toast-container * {
  box-sizing: border-box; }

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

#toast-container > div:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important; }

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important; }

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important; }

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important; }

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #51a351; }

.toast-error {
  background-color: #bd362f; }

.toast-info {
  background-color: #2f96b4; }

.toast-warning {
  background-color: #f89406; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; } }

/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
    ========================================================================== */
/**
  * 1. Set default font family to sans-serif.
  * 2. Prevent iOS and IE text size adjust after device orientation change,
  *    without disabling user zoom.
  * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
  *  `em` units.
  */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
  * Remove default margin.
  */
body {
  margin: 0; }

/* HTML5 display definitions
    ========================================================================== */
/**
  * Correct `block` display not defined for any HTML5 element in IE 8/9.
  * Correct `block` display not defined for `details` or `summary` in IE 10/11
  * and Firefox.
  * Correct `block` display not defined for `main` in IE 11.
  */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
  * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
  * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
  */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
  * Prevents modern browsers from displaying `audio` without controls.
  * Remove excess height in iOS 5 devices.
  */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
  * Address `[hidden]` styling not present in IE 8/9/10.
  * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
  */
[hidden],
template {
  display: none; }

/* Links
    ========================================================================== */
/**
  * Remove the gray background color from active links in IE 10.
  */
a {
  background-color: transparent; }
  a:active, a:hover {
    outline: 0; }

/* Text-level semantics
    ========================================================================== */
/**
  * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
  */
abbr[title] {
  border-bottom: 1px dotted; }

/**
  * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
  */
b,
strong {
  font-weight: bold; }

/**
  * Address styling not present in Safari and Chrome.
  */
dfn {
  font-style: italic; }

/**
  * Address variable `h1` font-size and margin within `section` and `article`
  * contexts in Firefox 4+, Safari, and Chrome.
  */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
  * Addresses styling not present in IE 8/9.
  */
mark {
  background: #ff0;
  color: #000; }

/**
  * Address inconsistent and variable font size in all browsers.
  */
small {
  font-size: 80%; }

/**
  * Prevent `sub` and `sup` affecting `line-height` in all browsers.
  */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
    ========================================================================== */
/**
  * 1. Remove border when inside `a` element in IE 8/9/10.
  * 2. Improves image quality when scaled in IE 7.
  */
img {
  border: 0; }

/**
  * Correct overflow not hidden in IE 9/10/11.
  */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
    ========================================================================== */
/**
  * Address margin not present in IE 8/9 and Safari.
  */
figure {
  margin: 1em 40px; }

/**
  * Address differences between Firefox and other browsers.
  */
hr {
  box-sizing: content-box;
  height: 0; }

/**
  * Contain overflow in all browsers.
  */
pre {
  overflow: auto; }

/**
  * Address odd `em`-unit font size rendering in all browsers.
  * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
  */
code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: 1em; }

/* Forms
    ========================================================================== */
/**
  * Known limitation: by default, Chrome and Safari on OS X allow very limited
  * styling of `select`, unless a `border` property is set.
  */
/**
  * 1. Correct color not being inherited.
  *  Known issue: affects color of disabled elements.
  * 2. Correct font properties not being inherited.
  * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
  * 4. Improves appearance and consistency in all browsers.
  */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
  * Address `overflow` set to `hidden` in IE 8/9/10/11.
  */
button {
  overflow: visible; }

/**
  * Address inconsistent `text-transform` inheritance for `button` and `select`.
  * All other form control elements do not inherit `text-transform` values.
  * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
  * Correct `select` style inheritance in Firefox.
  */
button,
select {
  text-transform: none; }

/**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
  *  and `video` controls.
  * 2. Correct inability to style clickable `input` types in iOS.
  * 3. Improve usability and consistency of cursor style between image-type
  *  `input` and others.
  * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
  *  Known issue: inner spacing remains in IE 6.
  */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
  * Re-set default cursor for disabled elements.
  */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
* Address Firefox 4+ setting `line-height` on `input` using `!important` in
* the UA stylesheet.
*/
input {
  line-height: normal; }

/**
  * Remove inner padding and border in Firefox 4+.
  */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
  * 1. Address box sizing set to `content-box` in IE 8/9/10.
  * 2. Remove excess padding in IE 8/9/10.
  *  Known issue: excess padding remains in IE 6.
  */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
  * Fix the cursor style for Chrome's increment/decrement buttons. For certain
  * `font-size` values of the `input`, it causes the cursor style of the
  * decrement button to change from `default` to `text`.
  */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

/**
  * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
  */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
  * Remove inner padding and search cancel button in Safari and Chrome on OS X.
  * Safari (but not Chrome) clips the cancel button when the search input has
  * padding (and `textfield` appearance).
  */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
  * Define consistent border, margin, and padding.
  */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
  * 1. Correct `color` not being inherited in IE 8/9/10/11.
  * 2. Remove padding so people aren't caught out if they zero out fieldsets.
  * 3. Corrects text not wrapping in Firefox 3.
  * 4. Corrects alignment displayed oddly in IE 6/7.
  */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
  * Remove default vertical scrollbar in IE 8/9/10/11.
  */
textarea {
  overflow: auto; }

/**
  * Don't inherit the `font-weight` (applied by a rule above).
  * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
  */
optgroup {
  font-weight: bold; }

/* Tables
    ========================================================================== */
/**
  * Remove most spacing between table cells.
  */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.slide-leave-active,
.slide-enter-active {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.slide-enter {
  transform: translateY(100%);
  transition-duration: 400ms; }

.slide-leave-to {
  transform: translateY(100%);
  transition-duration: 350ms; }

.text-center {
  text-align: center; }

.text--black {
  color: #000000; }

.text--lowercase {
  text-transform: lowercase; }

.text {
  font-size: 0.875rem;
  line-height: 18px;
  color: #000000;
  margin: 0;
  padding: 0; }
  .text--uppercase {
    text-transform: uppercase;
    letter-spacing: .7px; }
  .text--black {
    color: #000000; }
  .text--white {
    color: #ffffff; }
  .text--orange {
    color: #FA6400; }
  .text--grey {
    color: rgba(0, 0, 0, 0.32); }
  .text--silver {
    color: #A0A0B4; }
  .text--bold {
    font-family: "sofia-pro", sans-serif;
    font-weight: 600; }
  .text--large {
    font-size: 1.5rem;
    line-height: 29px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text--medium-big {
    font-size: 1.25rem;
    line-height: 29px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400; }
  .text--medium {
    font-size: 1.125rem;
    line-height: 27px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text--small {
    font-size: 0.875rem;
    line-height: 18px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text--exsmall {
    font-size: 0.75rem;
    line-height: 27px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text ul {
    list-style: default; }

strong {
  font-size: 1.25rem;
  line-height: 29px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 600; }

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-style: normal; }

:before {
  box-sizing: border-box; }

html {
  font-family: "sofia-pro", sans-serif;
  color: #000000;
  font-weight: 400; }

body {
  background: #ffffff; }
  body.menu-open {
    overflow: hidden; }

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0; }

button {
  background: transparent;
  border: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

a {
  text-decoration: none; }

.container {
  max-width: 1300px; }
  @media only screen and (max-width: 991px) {
    .container {
      max-width: 100%; } }

::-webkit-input-placeholder {
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  color: #A0A0B4; }

::-moz-placeholder {
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  color: #A0A0B4; }

:-ms-input-placeholder {
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  color: #A0A0B4; }

::-ms-input-placeholder {
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  color: #A0A0B4; }

::placeholder {
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  color: #A0A0B4; }

button:focus {
  outline: 0; }

.input--small {
  width: 22px !important;
  height: 22px !important; }

.list--disk {
  list-style-type: disc;
  margin-left: 20px !important; }

.u-p0 {
  padding: 0; }

.text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  color: #000000;
  margin: 0;
  padding: 0; }
  .text--uppercase {
    text-transform: uppercase;
    letter-spacing: .7px; }
  .text--black {
    color: #000000; }
  .text--white {
    color: #ffffff; }
  .text--orange {
    color: #FA6400; }
  .text--grey {
    color: rgba(0, 0, 0, 0.32); }
  .text--silver {
    color: #A0A0B4; }
  .text--bold {
    font-family: "sofia-pro", sans-serif;
    font-weight: 600; }
  .text--large {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 29px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text--medium-big {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 29px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400; }
  .text--medium {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 27px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text--small {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text--exsmall {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 27px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500; }
  .text ul {
    list-style: default; }

strong {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 29px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 600; }

.icon {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat; }
  .icon--link {
    cursor: pointer; }
  .icon--play {
    background-image: url("../images/icons/icon-play.svg");
    width: 20px;
    height: 20px; }
  .icon--down {
    background-image: url("../images/icons/icon-arrow-down-small.svg");
    width: 9px;
    height: 4px; }
  .icon--arrow--down {
    background-image: url("../images/icons/icon-arrow-down.svg");
    width: 15px;
    height: 7px; }
  .icon--arrow--down--black {
    background-image: url("../images/icons/icon-down-black.svg");
    width: 15px;
    height: 7px; }
  .icon--glass {
    background-image: url("../images/icons/icon-glass.svg");
    width: 62px;
    height: 61px; }
  .icon--notebook {
    background-image: url("../images/icons/icon-notebook.svg");
    width: 61px;
    height: 62px; }
  .icon--speaker {
    background-image: url("../images/icons/icon-speaker.svg");
    width: 67px;
    height: 64px; }
  .icon--handshake {
    background-image: url("../images/icons/icon-handshake.svg");
    width: 158px;
    height: 98px; }
  .icon--mail {
    background-image: url("../images/icons/icon-mail.svg");
    width: 20px;
    height: 14px; }
    .icon--mail:hover {
      background-image: url("../images/icons/icon-mail-hover.svg"); }
  .icon--linkdin {
    background-image: url("../images/icons/icon-linkedin.svg");
    width: 16px;
    height: 14px; }
    .icon--linkdin--small {
      background-image: url("../images/icons/icon-linkedin-small.svg");
      width: 16px;
      height: 14px; }
      .icon--linkdin--small:hover {
        background-image: url("../images/icons/icon-linkedin-small-hover.svg"); }
    .icon--linkdin--grey {
      background-image: url("../images/icons/icon-linkdin-grey.svg");
      width: 18px;
      height: 16px; }
  .icon--fb {
    background-image: url("../images/icons/icon-fb-black.svg");
    width: 20px;
    height: 20px; }
    .icon--fb--white {
      background-image: url("../images/icons/icon-fb-white.svg");
      width: 20px;
      height: 20px; }
    .icon--fb--small {
      background-image: url("../images/icons/icon-fb-small.svg");
      width: 8px;
      height: 14px; }
      .icon--fb--small:hover {
        background-image: url("../images/icons/icon-fb-small-hover.svg"); }
    .icon--fb--grey {
      background-image: url("../images/icons/icon-fb-grey.svg");
      width: 9px;
      height: 16px; }
  .icon--twitter {
    background-image: url("../images/icons/icon-twitter-black.svg");
    width: 23px;
    height: 19px; }
    .icon--twitter--white {
      background-image: url("../images/icons/icon-twitter-white.svg");
      width: 23px;
      height: 19px; }
    .icon--twitter--blue {
      background-image: url("../images/icons/icon-twitter-blue.svg");
      width: 23px;
      height: 19px; }
    .icon--twitter--small {
      background-image: url("../images/icons/icon-twitter-small.svg");
      width: 17px;
      height: 14px; }
      .icon--twitter--small:hover {
        background-image: url("../images/icons/icon-twitter-small-hover.svg"); }
    .icon--twitter--grey {
      background-image: url("../images/icons/icon-twitter-grey.svg");
      width: 20px;
      height: 16px; }
  .icon--form-attention {
    background-image: url("../images/icons/icon-exclamation.svg"); }
  .icon--form-logo {
    background-image: url("../images/main-logo.svg"); }
  .icon--form-link {
    background-image: url("../images/icons/icon-link-arrow.svg"); }
  .icon--form-pdf {
    background-image: url("../images/icons/icon-pdf.svg"); }
  .icon--form-success {
    background-image: url("../images/icons/icon-success.svg"); }
  .icon--list {
    background-image: url("../images/icons/icon-list.svg");
    width: 45px;
    height: 61px; }
  .icon--lamp {
    background-image: url("../images/icons/icon-lamp.svg");
    width: 42px;
    height: 60px; }

@-webkit-keyframes progress {
  0% {
    top: -35%;
    background: rgba(255, 255, 255, 0.1); }
  50% {
    top: -45%;
    background: rgba(255, 255, 255, 0.3); }
  51% {
    background: rgba(255, 255, 255, 0.5); }
  100% {
    background: rgba(255, 255, 255, 0.5); } }

@keyframes progress {
  0% {
    top: -35%;
    background: rgba(255, 255, 255, 0.1); }
  50% {
    top: -45%;
    background: rgba(255, 255, 255, 0.3); }
  51% {
    background: rgba(255, 255, 255, 0.5); }
  100% {
    background: rgba(255, 255, 255, 0.5); } }

@-webkit-keyframes flip {
  0% {
    bottom: -20%;
    background: rgba(255, 255, 255, 0.1); }
  50% {
    bottom: -40%;
    background: rgba(255, 255, 255, 0.3); }
  51% {
    background: rgba(255, 255, 255, 0.5); }
  100% {
    background: rgba(255, 255, 255, 0.5); } }

@keyframes flip {
  0% {
    bottom: -20%;
    background: rgba(255, 255, 255, 0.1); }
  50% {
    bottom: -40%;
    background: rgba(255, 255, 255, 0.3); }
  51% {
    background: rgba(255, 255, 255, 0.5); }
  100% {
    background: rgba(255, 255, 255, 0.5); } }

@-webkit-keyframes jump-arrow {
  from {
    bottom: 0; }
  to {
    bottom: -10px; } }

@keyframes jump-arrow {
  from {
    bottom: 0; }
  to {
    bottom: -10px; } }

.section-bg {
  min-height: 530px;
  display: flex;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, #f7dbd8 0%, #FCECDD 100%); }

.join-page .cover-block {
  min-height: 630px; }
  @media only screen and (max-width: 991px) {
    .join-page .cover-block {
      min-height: 350px; } }

.mission {
  background: #ffffff;
  padding: 8.1rem 0; }
  @media only screen and (max-width: 991px) {
    .mission {
      padding: 60px 5px; } }
  .mission__grid {
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 60px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; }
    @media only screen and (max-width: 991px) {
      .mission__grid {
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-column-gap: 0; } }
    @media only screen and (max-width: 768px) {
      .mission__grid {
        padding: 0; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .mission__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .mission__grid--about .mission__step {
      margin-bottom: 0; }
    .mission__grid--modified {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 40px;
      margin-top: 140px; }
      @media only screen and (max-width: 991px) {
        .mission__grid--modified {
          max-width: 100%;
          grid-template-columns: 1fr;
          grid-column-gap: 0;
          margin-top: 40px; } }
  .mission__step {
    background: linear-gradient(rgba(247, 219, 216, 0.4), #FCECDD);
    padding: 53px 32px;
    margin-bottom: 60px; }
    @media only screen and (max-width: 991px) {
      .mission__step {
        margin: 10px;
        padding: 30px 25px; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .mission__step {
        width: 32%; } }
  @media only screen and (max-width: 991px) {
    .mission__item {
      margin: 10px 0; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .mission__item {
      width: 49%; } }
  .mission__item--join {
    text-align: center;
    background: url("/images/backgrounds/join-us-cover.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 70px; }
    @media only screen and (max-width: 991px) {
      .mission__item--join {
        padding: 40px 15px; } }
  .mission__item--signup {
    text-align: center;
    background: url("/images/backgrounds/sign-up-cover.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 70px;
    overflow: hidden; }
    @media only screen and (max-width: 768px) {
      .mission__item--signup {
        padding: 60px 30px; } }
    .mission__item--signup .number-counter h1 {
      font-size: 180px;
      font-size: 11.25rem;
      line-height: 216px;
      color: #ffffff; }
  .mission__item p {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 42px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 100px; }
    @media only screen and (max-width: 991px) {
      .mission__item p {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 24px;
        margin: 0 0 50px; } }
  .mission__btn {
    margin-top: 177px; }
    @media only screen and (max-width: 991px) {
      .mission__btn {
        margin-top: 50px; } }
  .mission__icon {
    margin: 50px 0;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .mission__icon {
        margin: 0 0 23px; } }
    .mission__icon--modified:after {
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(-30deg);
      left: 38%;
      top: -30%;
      width: 4px;
      height: 21px;
      -webkit-animation: progress 3s linear infinite;
              animation: progress 3s linear infinite;
      border-radius: 2px; }
    .mission__icon--modified:before {
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(30deg);
      left: 38%;
      bottom: -30%;
      width: 4px;
      height: 21px;
      -webkit-animation: flip 3s linear infinite;
              animation: flip 3s linear infinite;
      border-radius: 2px; }
    .mission__icon--inner:before {
      left: 49%;
      top: -30%;
      width: 4px;
      height: 21px;
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(0deg);
      -webkit-animation: progress 3s linear infinite;
              animation: progress 3s linear infinite;
      border-radius: 2px; }
    .mission__icon--inner:after {
      left: 60%;
      top: -27%;
      width: 4px;
      height: 21px;
      transform: rotate(-148deg);
      content: "";
      letter-spacing: 0;
      position: absolute;
      -webkit-animation: progress 3s linear infinite;
              animation: progress 3s linear infinite;
      border-radius: 2px; }
    .mission__icon--outer:before {
      left: 49%;
      bottom: -30%;
      width: 4px;
      height: 21px;
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(0deg);
      -webkit-animation: flip 3s linear infinite;
              animation: flip 3s linear infinite;
      border-radius: 2px; }
    .mission__icon--outer:after {
      left: 60%;
      bottom: -30%;
      width: 4px;
      height: 21px;
      transform: rotate(148deg);
      content: "";
      letter-spacing: 0;
      position: absolute;
      -webkit-animation: flip 3s linear infinite;
              animation: flip 3s linear infinite;
      border-radius: 2px; }
  .mission__text {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    font-weight: 500; }
    @media only screen and (max-width: 991px) {
      .mission__text {
        font-size: 16px;
        font-size: 1rem;
        line-height: 19px; } }
  .mission__capture {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 29px;
    font-weight: 500;
    margin-top: 82px;
    display: block; }
    @media only screen and (max-width: 768px) {
      .mission__capture {
        margin-top: 35px; } }

.publication {
  background: #ffffff;
  max-width: 1200px;
  margin: -330px auto 0;
  padding: 55px 60px; }
  @media only screen and (max-width: 991px) {
    .publication {
      margin: 0 auto;
      padding: 30px 5px;
      max-width: 100%; } }
  .publication .container {
    max-width: 1140px; }
  .publication__copy {
    padding-left: 90px; }
    @media only screen and (max-width: 991px) {
      .publication__copy {
        padding-left: 0; } }
    @media only screen and (max-width: 991px) {
      .publication__copy h1 {
        text-align: center; } }
  @media only screen and (max-width: 991px) {
    .publication__cover {
      text-align: center; } }
  .publication__cover img {
    max-width: 100%;
    width: 100%; }
  .publication__actions {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .publication__actions a {
        margin-bottom: 16px; } }
    @media only screen and (max-width: 991px) {
      .publication__actions {
        flex-direction: column;
        justify-content: flex-start; } }

.proposal {
  max-width: 1200px;
  margin: 80px auto 130px;
  padding: 70px 0;
  background: url("/images/backgrounds/proposal-cover.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (max-width: 991px) {
    .proposal {
      margin: 70px auto; } }

@media only screen and (max-width: 991px) {
  body h4 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px; } }

.blog__row {
  display: grid;
  grid-row-gap: 3.75rem;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px; }
  @media only screen and (max-width: 991px) {
    .blog__row {
      grid-template-columns: 1fr;
      grid-row-gap: 1.75rem; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .blog__row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between; } }

.blog__body {
  background: linear-gradient(180deg, rgba(247, 219, 216, 0.5) 0%, #FEF1DE 100%); }

.blog--index {
  margin-top: 184px;
  margin-bottom: 140px; }

.blog__info, .civil-society__info {
  padding-top: 10rem;
  position: relative;
  width: 100%;
  min-height: 800px;
  padding-bottom: 350px; }
  @media only screen and (max-width: 991px) {
    .blog__info, .civil-society__info {
      padding-top: 6rem;
      min-height: 385px;
      padding-bottom: 60px; } }
  .blog__info h1, .civil-society__info h1 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 92px;
    letter-spacing: -0.75px; }
    @media only screen and (max-width: 991px) {
      .blog__info h1, .civil-society__info h1 {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 37px;
        letter-spacing: -1.13px; } }

@media only screen and (max-width: 991px) {
  .blog__text .col-lg-12, .civil-society__text .col-lg-12 {
    padding: 0; } }

.blog__text a, .civil-society__text a {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  color: #000000;
  text-decoration: underline; }
  .blog__text a:hover, .civil-society__text a:hover {
    color: #FA6400;
    text-decoration: none; }

.blog__text p, .civil-society__text p {
  font-weight: 400 !important;
  font-family: "sofia-pro", sans-serif !important;
  color: #000000 !important;
  margin: 0;
  padding: 0; }

.blog__text span, .civil-society__text span {
  font-weight: 400 !important;
  font-family: "sofia-pro", sans-serif !important;
  color: #000000 !important;
  margin: 0;
  padding: 0; }

.blog__capture {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  color: #A0A0B4; }

.blog__photo, .civil-society__embed {
  margin-top: -300px;
  text-align: center;
  min-height: 600px;
  height: 100%;
  max-height: 600px;
  margin-bottom: 0;
  overflow: hidden; }
  @media only screen and (max-width: 991px) {
    .blog__photo, .civil-society__embed {
      margin-top: 0;
      max-height: 190px;
      min-height: 190px; } }
  .blog__photo img, .civil-society__embed img {
    max-width: 100%;
    width: 100%;
    display: block; }

.blog__more {
  padding: 70px 0;
  margin-top: 70px;
  background: linear-gradient(180deg, rgba(247, 219, 216, 0.5) 0%, #FEF1DE 100%); }

.blog__cover, .civil-society__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, #F7DBD8 0%, #FEF1DE 100%);
  opacity: 0.5;
  transform: scaleY(-1); }

.blog__social, .civil-society__social {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 991px) {
    .blog__social, .civil-society__social {
      margin-left: 0 !important; } }
  .blog__social li, .civil-society__social li {
    margin-right: 12px; }
    .blog__social li:last-child, .civil-society__social li:last-child {
      margin-right: 0; }
  .blog__social a, .civil-society__social a {
    display: inline-flex;
    background: #ffffff;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

.blog__share--grey {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  @media only screen and (max-width: 991px) {
    .blog__share--grey {
      flex-direction: column; } }
  .blog__share--grey a {
    background: rgba(160, 160, 180, 0.1); }

.news-events__article {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .news-events__article {
      width: 49%;
      margin-bottom: 40px; } }
  .news-events__article:hover .news-events__cover {
    transform: scale(1.1); }
  .news-events__article-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .news-events__article-card {
    display: flex;
    flex-direction: column;
    height: 100%; }

.news-events__block {
  padding: 4.3rem 0;
  background: linear-gradient(rgba(247, 219, 216, 0.4), #FCECDD); }

.news-events__cover {
  max-height: 300px;
  min-height: 300px;
  height: 100%;
  position: relative;
  transition: all .8s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (max-width: 991px) {
    .news-events__cover {
      max-height: 166px;
      min-height: 166px; } }

.news-events__copy {
  background: #ffffff;
  padding: 20px 35px;
  margin: -50px 25px 0;
  position: relative;
  height: 100%;
  min-height: 0; }
  @media only screen and (max-width: 991px) {
    .news-events__copy {
      padding: 15px 20px;
      margin: -35px 10px 0; } }
  .news-events__copy .link--orange {
    margin-top: auto; }

.news-events__date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 27px;
  color: #A0A0B4;
  font-weight: 500;
  margin: 0 0 7px; }

.news-events__author {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 27px;
  color: #A0A0B4;
  font-weight: 500;
  margin: 0 0 7px; }

.news-events__excerpt {
  color: #000000; }

.cover-block {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 530px;
  background: linear-gradient(180deg, #F7DBD8 0%, #FEF1DE 100%);
  opacity: 0.5;
  transform: scaleY(-1); }
  @media only screen and (max-width: 991px) {
    .cover-block {
      min-height: 350px; } }
  .cover-block__info {
    position: absolute;
    top: 10rem;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      .cover-block__info {
        top: 6rem; } }

.cover-wrap--modified {
  min-height: 1060px; }
  @media only screen and (max-width: 991px) {
    .cover-wrap--modified {
      min-height: 600px; } }
  .cover-wrap--modified h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 92px;
    letter-spacing: -0.75px; }
    @media only screen and (max-width: 991px) {
      .cover-wrap--modified h2 {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 37px;
        letter-spacing: -1.13px; } }
  .cover-wrap--modified strong {
    font-size: 60px;
    font-size: 3.75rem;
    color: #FA6400; }
  .cover-wrap--modified .cover-block {
    min-height: 1060px; }
    @media only screen and (max-width: 991px) {
      .cover-wrap--modified .cover-block {
        min-height: 600px; } }

.contact-page h2 {
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 92px;
  letter-spacing: -0.75px; }
  @media only screen and (max-width: 991px) {
    .contact-page h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 37px;
      letter-spacing: -1.13px;
      margin-top: 40px; } }

.contact-page__info {
  padding: 130px 0; }
  @media only screen and (max-width: 991px) {
    .contact-page__info {
      padding: 40px 5px; } }
  @media only screen and (max-width: 991px) {
    .contact-page__info .link--bordered {
      font-size: 16px;
      font-size: 1rem;
      line-height: 29px; } }
  .contact-page__info .link--map:before {
    content: '';
    display: inline-block;
    height: 6px;
    width: 6px;
    background: url("/images/icons/icon-map-arrow.svg");
    background-size: cover;
    position: absolute;
    top: 8px;
    right: -7px;
    background-repeat: no-repeat; }

@media only screen and (max-width: 991px) {
  .text--medium-big {
    font-size: 16px;
    font-size: 1rem;
    line-height: 29px; } }

.proposal__wrap {
  position: relative;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 10;
  min-height: 100vh; }
  @media (max-width: 767.98px) {
    .proposal__wrap {
      background-color: #ffffff; } }

.proposal__column-one {
  width: 30%;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  padding-left: 55px; }
  @media (max-width: 767.98px) {
    .proposal__column-one {
      background-color: #ffffff;
      z-index: 30; } }
  @media (max-width: 767.98px) {
    .proposal__column-one.not-fixed {
      position: relative;
      order: 2;
      display: flex;
      padding: 50px 20px 100px 20px; } }
  @media (max-width: 991.98px) {
    .proposal__column-one {
      padding-left: 25px; } }
  @media (max-width: 767.98px) {
    .proposal__column-one {
      width: 100%; } }
  @media (max-width: 767.98px) {
    .proposal__column-one.dropdown-mobile {
      height: 90px;
      overflow: hidden;
      transition: height 300ms ease-out; }
      .proposal__column-one.dropdown-mobile .proposal__progress-bar {
        opacity: 0; } }
  .proposal__column-one .mobile-only {
    display: none; }
    @media (max-width: 767.98px) {
      .proposal__column-one .mobile-only {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 50px;
        right: 20px;
        -webkit-filter: invert(1);
                filter: invert(1);
        width: 40px;
        height: 40px;
        transform: rotateZ(180deg);
        transition: transform 200ms ease-in;
        background-color: #000000;
        border-radius: 50%; }
        .proposal__column-one .mobile-only span {
          display: block; } }
  @media (max-width: 767.98px) {
    .proposal__column-one.dropdown-active {
      height: 100%;
      transition: height 300ms ease-out; }
      .proposal__column-one.dropdown-active .mobile-only {
        transform: rotateZ(270deg);
        transition: transform 200ms ease-in; }
      .proposal__column-one.dropdown-active .proposal__progress-bar {
        opacity: 1;
        transition: opacity 600ms ease-in 300ms; } }

.proposal__form-logo {
  position: absolute;
  top: 20px;
  left: 55px;
  width: 100%;
  height: 45px; }
  @media (max-width: 767.98px) {
    .proposal__form-logo {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      padding: 10px;
      border-bottom: 1px solid #CACAD4;
      z-index: 100; } }
  .proposal__form-logo span {
    min-width: 200px;
    max-width: 250px;
    height: 45px;
    display: block;
    background-repeat: no-repeat; }
    @media (max-width: 991.98px) {
      .proposal__form-logo span {
        background-size: contain;
        height: 25px; } }
    @media (max-width: 767.98px) {
      .proposal__form-logo span {
        background-size: contain;
        height: 25px;
        background-position: center;
        max-width: 100%;
        width: 100%; } }

.proposal__column-two {
  width: 70%;
  margin-left: 30%;
  padding: 150px;
  background: linear-gradient(to bottom, rgba(254, 241, 222, 0.5) 0%, rgba(247, 219, 216, 0.5) 100%);
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
  position: absolute; }
  @media (max-width: 1199.98px) {
    .proposal__column-two {
      padding: 100px 40px 60px 60px; } }
  @media (max-width: 991.98px) {
    .proposal__column-two {
      padding: 40px 20px;
      align-items: center; } }
  @media (max-width: 767.98px) {
    .proposal__column-two {
      width: 100%;
      margin-left: 0%;
      padding: 100px 20px;
      position: relative; } }

.proposal__progress-bar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: flex-end; }

.proposal__progress-bar-item {
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #CACAD4;
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 20px 20px 0;
  line-height: 1.4;
  position: relative;
  max-height: 62px; }
  .proposal__progress-bar-item a {
    font-family: "sofia-pro", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #CACAD4;
    line-height: 1.4; }
  .proposal__progress-bar-item::before {
    content: "";
    position: absolute;
    display: block;
    width: 2px;
    height: 25px;
    background-color: #CACAD4;
    bottom: -9px;
    left: 9px; }
  .proposal__progress-bar-item:last-child:before {
    content: none; }
  .proposal__progress-bar-item .progress-check {
    width: 20px;
    height: 20px;
    border: 2px solid #CACAD4;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative; }
    .proposal__progress-bar-item .progress-check .progress-error {
      display: none;
      z-index: 5; }
    .proposal__progress-bar-item .progress-check svg {
      width: 10px;
      height: 7px;
      position: absolute;
      z-index: 10;
      top: 5px;
      left: 3px; }
  .proposal__progress-bar-item.error .progress-error {
    font-size: 12px;
    color: #ff0000;
    text-align: center;
    margin-top: -2px;
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: 10; }
  .proposal__progress-bar-item.error svg {
    display: none;
    z-index: 5; }
  .proposal__progress-bar-item.done {
    color: #000000; }
    .proposal__progress-bar-item.done svg {
      display: block;
      z-index: 20; }
    .proposal__progress-bar-item.done a {
      color: #000000; }
    .proposal__progress-bar-item.done:hover {
      cursor: pointer; }
    .proposal__progress-bar-item.done::before {
      background-color: #FA6400; }
    .proposal__progress-bar-item.done .progress-check {
      border: 2px solid #FA6400;
      background-color: #FA6400; }
  .proposal__progress-bar-item.router-link-active {
    color: #FA6400; }
    .proposal__progress-bar-item.router-link-active a {
      color: #FA6400; }
    .proposal__progress-bar-item.router-link-active .progress-check {
      border: 2px solid #FA6400; }

.proposal__save {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  width: 100%;
  margin-bottom: 27px; }

.proposal__submit, .proposal__start {
  font-family: "sofia-pro", sans-serif;
  background-color: #FA6400;
  border: 1px solid #FA6400;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  padding: 10px 40px 10px 40px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1.4;
  position: fixed;
  bottom: 20px;
  right: 55px;
  z-index: 20; }
  .proposal__submit.inactive, .proposal__start.inactive {
    background-color: #fad2bc;
    border-color: #fad2bc;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .proposal__submit.inactive:hover, .proposal__start.inactive:hover {
      cursor: default;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .proposal__submit.inactive:focus, .proposal__start.inactive:focus {
      outline: none; }
  .proposal__submit svg, .proposal__start svg {
    position: relative;
    margin-left: 10px;
    margin-top: 2px; }
    @media (max-width: 767.98px) {
      .proposal__submit svg, .proposal__start svg {
        position: relative;
        top: initial;
        right: initial;
        margin-left: 20px; } }

.proposal__save__btn {
  font-family: "sofia-pro", sans-serif;
  background-color: #FA6400;
  border: 1px solid #FA6400;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  font-weight: 600;
  width: auto;
  padding: 10px 40px 10px 40px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1.4;
  position: relative; }
  @media (max-width: 991.98px) {
    .proposal__save__btn {
      flex-wrap: nowrap;
      padding: 10px 20px 7px 20px; } }
  .proposal__save__btn:focus {
    outline: none; }
  .proposal__save__btn svg {
    position: relative;
    margin-right: 10px;
    transform: rotateY(180deg);
    margin-top: 2px; }

.proposal__start {
  align-self: flex-end;
  margin-top: 50px; }
  @media (max-width: 767.98px) {
    .proposal__start {
      position: fixed;
      bottom: 20px;
      left: 27px;
      right: 27px;
      width: calc(100% - 54px);
      z-index: 100;
      text-align: center;
      justify-content: center; } }

.proposal__intro {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  width: 100%; }
  .proposal__intro__main-heading {
    font-size: 60px;
    line-height: 80px;
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-weight: 600; }
    @media (max-width: 767.98px) {
      .proposal__intro__main-heading {
        font-size: 30px;
        line-height: 40px; } }
  .proposal__intro__heading {
    font-size: 24px;
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    line-height: 1.2;
    font-weight: 500;
    padding-top: 57px;
    max-width: 100%; }
  .proposal__intro__heading--bold {
    font-size: 24px;
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    padding-top: 70px;
    font-weight: 600;
    margin-bottom: 20px; }
  .proposal__intro__text {
    font-size: 16px;
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    padding-right: 20px;
    font-weight: 500;
    max-width: 100%; }
  .proposal__intro__list {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    list-style: initial;
    padding: 0 30px 30px 20px;
    font-weight: 500;
    font-size: 16px;
    max-width: 100%; }
  .proposal__intro__list-item {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    list-style: initial;
    padding: 0 0 5px 0;
    font-size: 16px;
    font-weight: 500; }
  .proposal__intro__tip {
    font-family: "sofia-pro", sans-serif;
    font-size: 16px;
    color: #A0A0B4;
    font-weight: 500;
    padding: 0 0 0 30px;
    position: relative;
    margin: 0;
    max-width: 100%; }
    .proposal__intro__tip span {
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      padding: 0;
      text-align: center;
      left: 0;
      top: 5px;
      background-color: #A0A0B4;
      border: 1px solid #A0A0B4;
      border-radius: 50%;
      color: #ffffff;
      font-size: 12px;
      line-height: 1.3; }
  .proposal__intro__email {
    border: 1px solid #CACAD4;
    border-radius: 4px;
    padding: 10px 10px;
    text-align: left;
    width: 220px; }
    .proposal__intro__email.invalid {
      border-color: #ff0000;
      background-position: right 18px center;
      background-repeat: no-repeat;
      background-size: 24px; }
    .proposal__intro__email.invalid + .invalid-field-text {
      display: block;
      text-align: left; }
    .proposal__intro__email::-webkit-input-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #000000;
      text-align: left; }
    .proposal__intro__email::-moz-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #000000;
      text-align: left; }
    .proposal__intro__email:-ms-input-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #000000;
      text-align: left; }
    .proposal__intro__email::-ms-input-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #000000;
      text-align: left; }
    .proposal__intro__email::placeholder {
      font-family: "sofia-pro", sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #000000;
      text-align: left; }
    .proposal__intro__email:focus {
      outline: none; }

.proposal__intro-logo {
  position: absolute;
  top: 20px;
  left: 55px;
  width: 100%;
  height: 45px; }
  @media (max-width: 767.98px) {
    .proposal__intro-logo {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      padding: 10px; } }
  .proposal__intro-logo span {
    min-width: 200px;
    max-width: 250px;
    height: 45px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (max-width: 991.98px) {
      .proposal__intro-logo span {
        height: 25px; } }
    @media (max-width: 767.98px) {
      .proposal__intro-logo span {
        background-size: contain;
        height: 25px;
        background-position: center;
        max-width: 100%;
        width: 100%; } }

.proposal__link {
  position: relative;
  color: #000000;
  font-family: "sofia-pro", sans-serif;
  position: relative;
  font-weight: 500; }
  .proposal__link.nobr {
    white-space: nowrap;
    display: inline-flex;
    flex-wrap: wrap;
    padding-right: 8px; }
  .proposal__link i {
    display: block;
    width: 6px;
    height: 6px;
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    right: 0;
    top: 3px;
    background-size: contain; }
  .proposal__link::before {
    content: "";
    position: absolute;
    width: 100%;
    display: block;
    height: 50%;
    bottom: 0;
    background-color: #FA6400;
    opacity: 0.3;
    right: 0;
    left: 0; }

.proposal__popup {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .proposal__popup .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 300ms ease-in; }
  .proposal__popup__content {
    display: flex;
    flex-wrap: wrap;
    width: 420px;
    height: 520px;
    background: linear-gradient(to bottom, #F7DBD8 0%, #FEF1DE 50%, #ffffff 50%, #ffffff 100%);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 110px 40px 60px 40px; }
  .proposal__popup__content-submit {
    display: flex;
    flex-wrap: wrap;
    width: 420px;
    height: 420px;
    background: #ffffff;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 40px; }
    @media (max-width: 767.98px) {
      .proposal__popup__content-submit {
        width: 80%;
        height: 60%; } }
    .proposal__popup__content-submit .success-checkmark {
      display: flex;
      width: 75px;
      height: 75px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 74px;
      margin-bottom: 40px; }
  .proposal__popup__heading {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-size: 24px;
    font-weight: 500; }
  .proposal__popup__submit-heading {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    display: inline-flex;
    width: 100%;
    justify-content: center; }
  .proposal__popup__text {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    text-align: center; }
    .proposal__popup__text .link-like {
      position: relative;
      color: #000000;
      font-family: "sofia-pro", sans-serif;
      font-weight: 500;
      margin: 0 5px;
      cursor: pointer; }
      .proposal__popup__text .link-like.nobr {
        white-space: nowrap; }
      .proposal__popup__text .link-like::before {
        content: "";
        position: absolute;
        width: 100%;
        display: block;
        height: 50%;
        bottom: 0;
        background-color: #FA6400;
        opacity: 0.3;
        right: 0;
        left: 0; }
  .proposal__popup__email {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    border: 1px solid #A0A0B4;
    border-radius: 24px;
    margin: 10px 0;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    min-height: 48px;
    max-height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .proposal__popup__email:focus {
      outline: none;
      border-color: #FA6400; }
  .proposal__popup__save {
    font-family: "sofia-pro", sans-serif;
    font-weight: 600;
    background-color: #FA6400;
    border: 1px solid #FA6400;
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    padding: 15px 35px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1.4;
    position: relative;
    font-size: 14px; }

.margin-b-74 {
  margin-bottom: 74px !important; }

@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0); }
  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.1); }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0); } }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0); }
  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.1); }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0); } }

.team-slider__wrap .container {
  max-width: 1200px; }

.team-slider__wrap .glide__slides > li {
  padding: 13px;
  height: inherit;
  position: relative; }

.team-slider__wrap-socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px; }
  .team-slider__wrap-socials li {
    margin: 0 10px; }
    .team-slider__wrap-socials li span {
      opacity: .7; }
      .team-slider__wrap-socials li span:hover {
        opacity: 1; }

.team-slider__wrap .glide__arrow--left {
  border: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  background-image: url("../images/icons/arrow-big-left.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 24px;
  font-size: 0;
  left: -2rem;
  position: absolute;
  top: 37%; }
  @media only screen and (max-width: 991px) {
    .team-slider__wrap .glide__arrow--left {
      left: 0; } }

.team-slider__wrap .glide__arrow--right {
  border: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  background-image: url("../images/icons/arrow-big-right.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 24px;
  font-size: 0;
  right: -2rem;
  position: absolute;
  top: 37%; }
  @media only screen and (max-width: 991px) {
    .team-slider__wrap .glide__arrow--right {
      right: 0; } }

.civil-society__cover {
  min-height: none; }
  @media only screen and (max-width: 991px) {
    .civil-society__cover {
      min-height: 340px; } }
  @media only screen and (max-width: 576px) {
    .civil-society__cover {
      min-height: 500px; } }

.civil-society__text {
  padding-left: 15px;
  padding-right: 15px; }
  @media only screen and (max-width: 991px) {
    .civil-society__text {
      padding: 30px; } }

.civil-society__embed {
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  max-height: none;
  padding-bottom: 100px; }
  .civil-society__embed iframe {
    height: 1025px; }
  @media only screen and (max-width: 576px) {
    .civil-society__embed iframe {
      height: 600px; } }
  @media only screen and (max-width: 991px) {
    .civil-society__embed {
      padding-bottom: 0; } }

.btn {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 14px;
  letter-spacing: .7px;
  height: 48px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  border-radius: 5px;
  padding: 16px 35px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "sofia-pro", sans-serif;
  position: relative;
  z-index: 2;
  overflow: hidden; }
  .btn:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    background-color: #000000;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .5s ease;
    z-index: -1;
    border-radius: 5px; }
  .btn:not(.btn--disabled):hover:before {
    width: 100%; }
  .btn--orange {
    background: #FA6400; }
    .btn--orange:hover {
      color: #ffffff;
      z-index: 2; }
  .btn--secondary {
    background: rgba(160, 160, 180, 0.1);
    color: #000000; }
    .btn--secondary:not(.btn--disabled):hover {
      color: #ffffff;
      z-index: 2; }
  .btn--white {
    background: #ffffff;
    color: #000000; }
    .btn--white:hover {
      color: #ffffff;
      z-index: 2; }
  .btn--large {
    padding: 16px 40px; }
  .btn--white--bordered {
    border: 2px solid #FA6400;
    color: #FA6400 !important;
    text-transform: uppercase; }
    .btn--white--bordered:hover {
      background: #FA6400;
      color: #ffffff !important; }
  .btn--disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .btn--full-width {
    width: 100%; }

.link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 27px;
  font-weight: 600;
  font-family: "sofia-pro", sans-serif;
  letter-spacing: 0.7px;
  color: #000000;
  display: inline-block;
  position: relative; }
  .link:hover {
    color: #FA6400;
    text-decoration: none; }
  .link--orange {
    color: #FA6400; }
  .link--white {
    color: #ffffff; }
  .link--grey {
    color: rgba(0, 0, 0, 0.32); }
  .link--underline {
    text-decoration: underline; }
    .link--underline:hover {
      text-decoration: none; }
  .link--bordered {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 29px;
    font-family: "sofia-pro", sans-serif;
    position: relative;
    display: inline-block;
    letter-spacing: -0.3px;
    font-weight: 400; }
    .link--bordered:after {
      content: '';
      background: rgba(250, 100, 0, 0.3);
      height: 11px;
      width: 100%;
      display: block;
      bottom: 4px;
      position: absolute; }

.h1,
h1 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  font-family: "sofia-pro", sans-serif;
  letter-spacing: 0.7px;
  margin: 0;
  color: #000000;
  font-weight: 600; }

.h2,
h2 {
  font-size: 80px;
  font-size: 5rem;
  line-height: 96px;
  letter-spacing: -3px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 600;
  color: #000000;
  margin: 0; }
  @media only screen and (max-width: 991px) {
    .h2,
    h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 37px;
      letter-spacing: -1.13px; } }

.h3,
h3 {
  font-size: 35px;
  font-size: 2.1875rem;
  line-height: 42px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 600;
  color: #000000;
  margin: 0; }
  @media only screen and (max-width: 991px) {
    .h3,
    h3 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px; } }

.h4,
h4 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 37px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 600;
  color: #000000;
  margin: 0; }

.h5,
h5 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 29px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 500;
  color: #000000;
  margin: 0; }

.h6,
h6 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  font-family: "sofia-pro", sans-serif;
  color: #000000;
  font-weight: 500;
  margin: 0; }

.form__section {
  margin-bottom: 150px;
  max-width: 650px; }
  .form__section__about {
    padding-bottom: 10px; }
  .form__section__title {
    font-family: "sofia-pro", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    line-height: 1.2;
    margin-bottom: 21px; }
    .form__section__title.main-heading {
      font-size: 35px;
      line-height: 42px; }
      @media (max-width: 767.98px) {
        .form__section__title.main-heading {
          font-size: 30px; } }
    .form__section__title.details-heading {
      font-size: 24px;
      line-height: 29px; }
      @media (max-width: 767.98px) {
        .form__section__title.details-heading {
          font-size: 20px;
          line-height: 25px; } }
  .form__section__tip {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px; }
    @media (max-width: 767.98px) {
      .form__section__tip {
        font-size: 16px;
        margin-bottom: 30px; } }
    .form__section__tip--attention {
      display: flex;
      flex-wrap: wrap;
      line-height: 1.2;
      color: #ff0000; }
      .form__section__tip--attention span {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 10px; }
    .form__section__tip .form-link {
      position: relative;
      color: #000000;
      font-family: "sofia-pro", sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px; }
      .form__section__tip .form-link.nobr {
        white-space: nowrap; }
      .form__section__tip .form-link::before {
        content: "";
        position: absolute;
        width: 100%;
        display: block;
        height: 50%;
        bottom: 0;
        background-color: #FA6400;
        opacity: 0.3;
        right: 0;
        left: 0; }
  .form__section__button {
    font-family: "sofia-pro", sans-serif;
    font-weight: 600;
    background-color: #FA6400;
    border: 1px solid #FA6400;
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    padding: 10px 30px 10px 30px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1.4;
    position: fixed;
    bottom: 20px;
    right: 55px;
    z-index: 20; }
    @media (max-width: 767.98px) {
      .form__section__button {
        flex-wrap: nowrap;
        bottom: 40px;
        right: 20px;
        width: auto; } }
    .form__section__button.inactive {
      background-color: #fad2bc;
      border-color: #fad2bc;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .form__section__button.inactive:hover {
        cursor: default;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }
      .form__section__button.inactive:focus {
        outline: none; }
    .form__section__button svg {
      position: relative;
      margin-left: 10px;
      margin-top: 2px; }

.form__input__text {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 9px; }

.form__input__tip-text {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px; }

.form__input__value {
  border: 1px solid #CACAD4;
  height: 56px;
  border-radius: 4px;
  width: 100%;
  max-width: 650px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 10px transparent inset;
  transition: background-color 500s ease-in-out 0s; }
  .form__input__value[name] {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding: 10px 46px 10px 18px; }
  .form__input__value:-internal-autofill-selected {
    -webkit-text-fill-color: #000000; }
  .form__input__value:focus {
    outline-color: #FA6400; }
  .form__input__value.invalid {
    border: 1px solid #ff0000;
    background-position: right 18px center;
    background-repeat: no-repeat;
    background-size: 24px; }
  .form__input__value.invalid + .invalid-field-text {
    display: block; }
  .form__input__value.max-limit {
    border: 1px solid red; }
    .form__input__value.max-limit:focus {
      outline-color: red; }
  .form__input__value.max-limit + form__input__count {
    color: #ff0000; }

.form__input__count {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #A0A0B4;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.form__label {
  display: flex;
  flex-wrap: wrap;
  max-width: 650px;
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px; }
  .form__label .invalid-field-text {
    display: none;
    text-align: right;
    margin-top: 6px;
    color: #ff0000; }
  .form__label:hover svg {
    fill: yellowgreen;
    transition: all 200ms ease-in; }
  .form__label input[checked] + svg {
    fill: orange; }

.form__radio {
  margin-bottom: 30px; }
  .form__radio__item.radio-proposal-timeframe .bold-text {
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000; }
  .form__radio__item.radio-proposal-timeframe .tip-text {
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #A0A0B4;
    display: block;
    width: 100%; }
  .form__radio__item.radio-about, .form__radio__item.radio-behalf, .form__radio__item.radio-proposal-timeframe {
    margin: 16px 0; }
    .form__radio__item.radio-about.md-radio-inline, .form__radio__item.radio-behalf.md-radio-inline, .form__radio__item.radio-proposal-timeframe.md-radio-inline {
      display: inline-block; }
    .form__radio__item.radio-about input[type="radio"], .form__radio__item.radio-behalf input[type="radio"], .form__radio__item.radio-proposal-timeframe input[type="radio"] {
      display: none; }
      .form__radio__item.radio-about input[type="radio"]:checked + label:before, .form__radio__item.radio-behalf input[type="radio"]:checked + label:before, .form__radio__item.radio-proposal-timeframe input[type="radio"]:checked + label:before {
        border-color: #FA6400;
        -webkit-animation: ripple 0.2s linear forwards;
                animation: ripple 0.2s linear forwards; }
      .form__radio__item.radio-about input[type="radio"]:checked + label:after, .form__radio__item.radio-behalf input[type="radio"]:checked + label:after, .form__radio__item.radio-proposal-timeframe input[type="radio"]:checked + label:after {
        transform: scale(1); }
      .form__radio__item.radio-about input[type="radio"]:checked + label, .form__radio__item.radio-behalf input[type="radio"]:checked + label, .form__radio__item.radio-proposal-timeframe input[type="radio"]:checked + label {
        color: #FA6400;
        border: 1px solid #FA6400; }
        .form__radio__item.radio-about input[type="radio"]:checked + label .bold-text, .form__radio__item.radio-behalf input[type="radio"]:checked + label .bold-text, .form__radio__item.radio-proposal-timeframe input[type="radio"]:checked + label .bold-text {
          color: #FA6400; }
    .form__radio__item.radio-about label, .form__radio__item.radio-behalf label, .form__radio__item.radio-proposal-timeframe label {
      line-height: 1.2;
      font-size: 18px;
      line-height: 24px;
      font-family: "sofia-pro", sans-serif;
      max-width: 650px;
      width: 100%;
      font-weight: 500;
      background-color: #ffffff;
      display: inline-block;
      position: relative;
      padding: 18px 55px;
      margin-bottom: 0;
      cursor: pointer;
      border: 1px solid #CBCBD6;
      border-radius: 4px; }
      .form__radio__item.radio-about label:before, .form__radio__item.radio-about label:after, .form__radio__item.radio-behalf label:before, .form__radio__item.radio-behalf label:after, .form__radio__item.radio-proposal-timeframe label:before, .form__radio__item.radio-proposal-timeframe label:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all .3s ease;
        transition-property: transform, border-color; }
      .form__radio__item.radio-about label:before, .form__radio__item.radio-behalf label:before, .form__radio__item.radio-proposal-timeframe label:before {
        left: 20px;
        top: 18px;
        width: 20px;
        height: 20px;
        border: 2px solid rgba(0, 0, 0, 0.54); }
      .form__radio__item.radio-about label:after, .form__radio__item.radio-behalf label:after, .form__radio__item.radio-proposal-timeframe label:after {
        top: 23px;
        left: 25px;
        width: 10px;
        height: 10px;
        transform: scale(0);
        background: #FA6400; }
  .form__radio__item.radio-filter {
    margin-bottom: 16px; }
    .form__radio__item.radio-filter.md-radio-inline {
      display: inline-block; }
    .form__radio__item.radio-filter input[type="radio"] {
      display: none; }
      .form__radio__item.radio-filter input[type="radio"]:checked + label:after {
        transform: scale(1); }
      .form__radio__item.radio-filter input[type="radio"]:checked + label {
        color: #FA6400; }
    .form__radio__item.radio-filter label {
      line-height: 1.2;
      font-size: 18px;
      line-height: 24px;
      font-family: "sofia-pro", sans-serif;
      max-width: 650px;
      width: 100%;
      font-weight: 500;
      background-color: #ffffff;
      display: inline-block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 0;
      cursor: pointer; }
      .form__radio__item.radio-filter label:before, .form__radio__item.radio-filter label:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all .3s ease;
        transition-property: transform, border-color; }
      .form__radio__item.radio-filter label:before {
        left: 0;
        top: 3px;
        width: 20px;
        height: 20px;
        border: 2px solid rgba(0, 0, 0, 0.54); }
      .form__radio__item.radio-filter label:after {
        top: 8px;
        left: 5px;
        width: 10px;
        height: 10px;
        transform: scale(0);
        background: #FA6400; }

.form__upload-file {
  margin-bottom: 100px; }
  .form__upload-file .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .form__upload-file .remove-file-upload {
    color: #FA6400; }
  .form__upload-file label {
    padding: 10px 0; }
    .form__upload-file label:focus {
      outline: none; }
  .form__upload-file .inputfile + label {
    display: inline-block; }
  .form__upload-file .choose-file-text {
    background-color: #ffffff;
    padding: 15px 60px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background-repeat: no-repeat;
    text-transform: uppercase;
    background-position: left 25px center; }
  .form__upload-file .inputfile:focus + label,
  .form__upload-file .inputfile + label:hover {
    background-color: transparent; }
  .form__upload-file .inputfile + label {
    cursor: pointer; }
  .form__upload-file .inputfile:focus + label {
    outline: 1px dotted #A0A0B4;
    outline: -webkit-focus-ring-color auto 5px; }
  .form__upload-file .inputfile + label * {
    pointer-events: none; }

.form__statement-check {
  font-family: "sofia-pro", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .form__statement-check .statement-check-text {
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    color: #000000; }
    .form__statement-check .statement-check-text a {
      color: #000000;
      text-decoration: underline; }
  .form__statement-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .form__statement-check input:checked ~ .checkmark {
      background-color: #ffffff;
      border: 1px solid #FA6400; }
      .form__statement-check input:checked ~ .checkmark:after {
        display: block;
        border-color: #FA6400; }
    .form__statement-check input:checked ~ .risk__multipliers__text {
      color: #FA6400; }
  .form__statement-check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #A0A0B4;
    border-radius: 4px; }
    .form__statement-check .checkmark:after {
      content: "";
      position: absolute;
      display: none; }
    .form__statement-check .checkmark:after {
      left: 6px;
      top: 3px;
      width: 4px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }
  .form__statement-check:hover input ~ .checkmark {
    background-color: #ffffff; }

.form__textarea__value {
  border: 1px solid #ffffff;
  border-radius: 4px;
  width: 100%;
  max-width: 650px;
  color: #000000;
  font-family: "sofia-pro", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding: 10px 18px;
  background-color: #ffffff; }
  @media (max-width: 767.98px) {
    .form__textarea__value {
      background-color: #ffffff; } }
  .form__textarea__value.max-limit {
    border: 1px solid red; }
    .form__textarea__value.max-limit:focus {
      outline-color: red; }
  .form__textarea__value:focus {
    outline-color: #FA6400; }

.risks__wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  max-width: 650px; }

.risks__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 25px;
  margin-right: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 28%; }
  @media (max-width: 767.98px) {
    .risks__item {
      width: 100%;
      margin-right: 0; } }
  .risks__item input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .risks__item input:checked ~ .checkmark {
      border: 1px solid #FA6400; }
      .risks__item input:checked ~ .checkmark svg {
        fill: #FA6400; }
        .risks__item input:checked ~ .checkmark svg path {
          stroke: #FA6400 !important; }
      .risks__item input:checked ~ .checkmark .checkmark__text {
        color: #FA6400; }
    .risks__item input:checked ~ .checkmark:after {
      display: block; }
    .risks__item input:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .risks__item .checkmark {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 10px;
    min-height: 240px; }
    @media (max-width: 767.98px) {
      .risks__item .checkmark {
        width: 100%;
        min-height: 80px;
        height: 80px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start; } }
    .risks__item .checkmark:after {
      content: "";
      position: absolute;
      display: none; }
    .risks__item .checkmark__text {
      font-family: "sofia-pro", sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      text-align: center; }
      @media (max-width: 767.98px) {
        .risks__item .checkmark__text {
          margin-left: 13px;
          text-align: left; } }
    @media (max-width: 767.98px) {
      .risks__item .checkmark svg {
        height: 60px;
        width: 60px; } }
  .risks__item:hover input ~ .checkmark {
    border: 1px solid #FA6400; }
    .risks__item:hover input ~ .checkmark svg {
      fill: #FA6400; }
      .risks__item:hover input ~ .checkmark svg path {
        stroke: #FA6400 !important; }
    .risks__item:hover input ~ .checkmark .checkmark__text {
      color: #FA6400; }

.risks__multipliers {
  font-family: "sofia-pro", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .risks__multipliers input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .risks__multipliers input:checked ~ .checkmark {
      background-color: #ffffff;
      border: 2px solid #FA6400; }
      .risks__multipliers input:checked ~ .checkmark:after {
        display: block;
        border-color: #FA6400; }
    .risks__multipliers input:checked ~ .risk__multipliers__text {
      color: #FA6400; }
  .risks__multipliers .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 2px solid #A0A0B4;
    border-radius: 4px; }
    .risks__multipliers .checkmark:after {
      content: "";
      position: absolute;
      display: none; }
    .risks__multipliers .checkmark:after {
      left: 5px;
      top: 2px;
      width: 4px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }
  .risks__multipliers:hover input ~ .checkmark {
    background-color: #ffffff; }

.countdown-block {
  background: linear-gradient(rgba(247, 219, 216, 0.4), #FCECDD);
  padding: 7.5rem 0 0;
  min-height: 1223px; }
  @media only screen and (max-width: 991px) {
    .countdown-block {
      padding: 60px 0 40px;
      min-height: auto; } }

.countdown__wrap {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 991px) {
    .countdown__wrap {
      flex-direction: column; } }

.countdown__count {
  font-size: 160px;
  font-size: 10rem;
  line-height: 192px;
  display: inline-block;
  position: relative;
  color: #000000; }
  @media only screen and (max-width: 991px) {
    .countdown__count {
      text-align: center; } }

.countdown__item {
  position: relative;
  text-align: center; }

.countdown-component {
  position: relative;
  margin: 70px 0; }
  .countdown-component__label {
    text-align: center;
    position: relative; }
  .countdown-component__circle {
    width: 75px;
    height: 75px;
    position: absolute;
    top: -7%;
    right: -12%; }
  .countdown-component__wrap {
    margin: 70px 0; }
    .countdown-component__wrap svg {
      transform: scale(0.4);
      position: absolute;
      top: -15%;
      right: -66px;
      z-index: 2; }

.animation_start {
  -webkit-animation: countDown 3s cubic-bezier(0.14, 0.84, 0.9, 1);
          animation: countDown 3s cubic-bezier(0.14, 0.84, 0.9, 1); }

@-webkit-keyframes countDown {
  0% {
    stroke-dashoffset: 0; } }

@keyframes countDown {
  0% {
    stroke-dashoffset: 0; } }

.section-form__block {
  background: #ffffff;
  position: relative;
  border-radius: 5px; }
  .section-form__block label {
    font-size: 16px;
    font-size: 1rem;
    line-height: 19;
    font-weight: 500;
    position: absolute;
    color: #A0A0B4;
    display: block;
    margin: 0;
    z-index: 1; }

.section-form__input {
  font-size: 16px;
  font-size: 1rem;
  line-height: 23px;
  color: #000000;
  width: 100%;
  background: #ffffff;
  padding: 10px 20px;
  border: none;
  height: 48px;
  border-radius: 5px; }

.section-form .invalid-feedback {
  display: block; }

.twitter-widget {
  margin-bottom: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 768px) {
    .twitter-widget {
      flex-direction: column; } }
  .twitter-widget__post {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 23px;
    background: #ffffff;
    box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    padding: 40px;
    width: 33.3%;
    margin: 0 20px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .twitter-widget__post {
        width: auto;
        margin: 20px; } }
  .twitter-widget__post-link {
    transition: color 0.2s ease; }
    .twitter-widget__post-link:hover {
      color: #FA6400; }

.tabs-primary {
  display: flex;
  flex-direction: row;
  margin-top: -32px;
  position: relative;
  background: #ffffff;
  border-radius: 8px; }
  @media only screen and (max-width: 991px) {
    .tabs-primary {
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 30px; } }
  .tabs-primary a {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 600;
    color: #000000;
    display: inline-block;
    padding: 22px 50px;
    border: 1px solid #D1D1E2;
    border-radius: 0 8px 8px 0;
    background-color: #ffffff; }
    @media only screen and (max-width: 991px) {
      .tabs-primary a {
        padding: 22px;
        max-width: 375px;
        min-width: 250px;
        width: 100%;
        text-align: center; } }
  .tabs-primary__active {
    border: 1px solid #FA6400 !important;
    box-shadow: 0 0 0 3px #FA6400 inset;
    background-color: rgba(250, 100, 0, 0.06) !important;
    color: #FA6400 !important; }
  .tabs-primary__left-tab {
    border-radius: 8px 0 0 8px; }
    @media only screen and (max-width: 991px) {
      .tabs-primary__left-tab {
        border-radius: 8px 8px 0 0; } }
    .tabs-primary__left-tab a {
      border-radius: 8px 0 0 8px; }
      @media only screen and (max-width: 991px) {
        .tabs-primary__left-tab a {
          border-radius: 8px 8px 0 0; } }
  .tabs-primary__right-tab {
    border-radius: 0 8px 8px 0; }
    @media only screen and (max-width: 991px) {
      .tabs-primary__right-tab {
        border-radius: 0 0 8px 8px; } }
    .tabs-primary__right-tab a {
      border-radius: 0 8px 8px 0; }
      @media only screen and (max-width: 991px) {
        .tabs-primary__right-tab a {
          border-radius: 0 0 8px 8px; } }

.tab-pane {
  display: none; }
  .tab-pane.active-tab {
    display: block; }

.form-primary__label {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 23px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 500;
  display: block; }

.form-primary__block {
  margin-bottom: 30px;
  width: 100%; }

.form-primary__field {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 23px;
  font-weight: 500;
  background: #ffffff;
  width: 100%;
  padding: 15px 20px;
  border: 1px solid rgba(160, 160, 180, 0.5);
  border-radius: 4px;
  resize: none; }

.form-primary .invalid-feedback {
  display: block; }

.checkbox__collections {
  display: flex;
  justify-content: space-between; }
  .checkbox__collections-wrap:nth-child(1) {
    margin-right: 60px;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      .checkbox__collections-wrap:nth-child(1) {
        margin-right: 0; } }

.checkbox__wrap {
  display: flex;
  position: relative;
  margin-bottom: 20px; }
  .checkbox__wrap--modified p {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 14px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400; }
  .checkbox__wrap--modified .checkbox__label:before {
    border: none;
    background: #EFEFEF; }
  .checkbox__wrap--modified .checkbox__label--checked p {
    color: #000000; }

.checkbox__label {
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 0;
  top: 4px; }
  .checkbox__label:before {
    content: "";
    margin-right: 15px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border: 2px solid #000000;
    background: #ffffff;
    border-radius: 4px; }
  .checkbox__label .text--bold {
    line-height: 23px; }
  .checkbox__label--checked:before {
    border: 2px solid #FA6400; }
  .checkbox__label--checked:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: #ffffff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #FA6400, 4px 0 0 #FA6400, 4px -2px 0 #FA6400, 4px -4px 0 #FA6400, 4px -6px 0 #FA6400, 4px -8px 0 #FA6400;
    transform: rotate(45deg); }
  .checkbox__label--checked p {
    color: #FA6400; }
  .checkbox__label--checked-input:before {
    border: 2px solid #FA6400; }
  .checkbox__label--checked-input:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: #ffffff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #FA6400, 4px 0 0 #FA6400, 4px -2px 0 #FA6400, 4px -4px 0 #FA6400, 4px -6px 0 #FA6400, 4px -8px 0 #FA6400;
    transform: rotate(45deg); }

.checkbox__input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer; }

.header--slider {
  position: relative;
  min-height: 920px; }
  @media only screen and (max-width: 768px) {
    .header--slider {
      min-height: 520px; } }
  @media only screen and (max-width: 576px) {
    .header--slider {
      min-height: 520px; } }
  .header--slider__wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex; }
    @media only screen and (max-width: 768px) {
      .header--slider__wrapper {
        position: relative;
        height: auto; } }
  .header--slider .glide {
    display: flex;
    height: 100%; }
  .header--slider .glide__image {
    background-size: cover;
    min-height: 920px;
    background-position: center;
    display: flex;
    height: 100%; }
    @media only screen and (max-width: 768px) {
      .header--slider .glide__image {
        min-height: 520px; } }
    @media only screen and (max-width: 576px) {
      .header--slider .glide__image {
        min-height: 520px; } }
  .header--slider .glide__slides {
    transform: none !important;
    width: auto !important;
    display: flex;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0; }
  .header--slider .glide__slide {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 2s;
    transition: opacity 0s ease 2s; }
  .header--slider .glide__slide:first-child {
    position: relative; }
  .header--slider .glide__slide--active {
    z-index: 1;
    opacity: 1;
    transition: opacity 2s; }

.odometer-digit {
  font-size: 11.25rem;
  line-height: 216px;
  color: #ffffff; }
  @media only screen and (max-width: 768px) {
    .odometer-digit {
      font-size: 8rem;
      line-height: 165px; } }

.odometer-inside {
  position: relative; }
  .odometer-inside:before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    z-index: 5;
    box-shadow: 0px -12px 31px 67px #5A636C;
    top: -16px; }
    @media only screen and (max-width: 768px) {
      .odometer-inside:before {
        top: 8px;
        box-shadow: 0px -12px 31px 40px #5A636C; } }
  .odometer-inside:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    z-index: 5;
    box-shadow: 0px -12px 35px 65px #5A636C;
    bottom: -32px; }
    @media only screen and (max-width: 768px) {
      .odometer-inside:after {
        bottom: -12px;
        box-shadow: 0px -12px 31px 40px #5A636C; } }

.number-counter {
  box-shadow: 0px -12px 31px 67px #5A636C; }

.multiselect {
  font-size: 16px;
  font-size: 1rem;
  line-height: 23px;
  color: #000000;
  width: 100%;
  background: #ffffff;
  padding: 0;
  border: 1px solid rgba(160, 160, 180, 0.5);
  border-radius: 4px;
  height: 48px;
  position: relative; }
  .multiselect__tags {
    background-color: transparent;
    border: none;
    padding-left: 0; }
  .multiselect__placeholder {
    margin-top: 11px;
    display: inline-block;
    margin-left: 10px; }
  .multiselect__content-wrapper {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid #CBCBD6;
    background: #ffffff;
    position: absolute;
    overflow: auto;
    z-index: 200;
    width: 100%; }
  .multiselect__input {
    padding: 0 !important;
    border: none;
    background: #ffffff;
    padding: 11px 30px 11px 12px !important;
    width: 100% !important;
    border-radius: 4px;
    z-index: -1; }
  .multiselect__single {
    width: 90%;
    display: inline-block;
    height: inherit;
    padding: 11px 0 11px 12px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .multiselect__content {
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top; }
    .multiselect__content li {
      width: 100%;
      display: block; }
      .multiselect__content li .multiselect__option {
        display: block;
        width: 100%;
        cursor: pointer; }
  .multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 7px;
    padding: 4px 8px;
    text-align: center;
    z-index: 2; }
    .multiselect__select:before {
      content: '';
      background: url("../images/icons/arrow-select.svg");
      border: none;
      display: inline-block;
      width: 16px;
      height: 8px;
      background-size: contain;
      background-repeat: no-repeat;
      top: 3px; }
  .multiselect__option {
    font-size: 16px;
    font-size: 1rem;
    line-height: 14px;
    color: #000000;
    padding: 16px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400; }
    .multiselect__option--highlight {
      background: rgba(203, 203, 214, 0.5) !important; }
    .multiselect__option--selected {
      background: rgba(203, 203, 214, 0.7) !important; }
    .multiselect__option--highlight:after {
      background: none !important;
      color: #000000; }
  .multiselect__option--selected {
    font-weight: 300; }
    .multiselect__option--selected.multiselect__option--highlight:after {
      color: #000000; }

.swal2-container .swal2-popup {
  max-width: 420px;
  max-height: 420px;
  width: 100%;
  height: 100%;
  padding: 60px;
  border-radius: 4px;
  background: #ffffff; }

.swal2-container .swal2-success {
  margin: 0 auto 40px;
  width: 70px;
  height: 70px;
  background-image: url("../images/icons/icon-success.svg");
  border-color: transparent !important;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat; }
  .swal2-container .swal2-success > div {
    display: none; }
  .swal2-container .swal2-success .swal2-success-line-tip {
    display: none !important; }
  .swal2-container .swal2-success .swal2-success-line-long {
    display: none !important; }

.swal2-container .swal2-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 29px;
  font-weight: 500;
  color: #000000;
  font-family: "sofia-pro", sans-serif;
  margin-bottom: 12px;
  letter-spacing: inherit; }

.swal2-container .swal2-content {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 23px;
  color: #000000;
  text-align: center;
  font-weight: 500;
  font-family: "sofia-pro", sans-serif; }

.swal2-container .swal2-actions {
  margin-top: 50px; }
  .swal2-container .swal2-actions button {
    height: 48px;
    width: 140px;
    border-radius: 4px;
    background-color: #FA6400;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "sofia-pro", sans-serif;
    padding: 15px 40px;
    margin: 0;
    border: 0 !important;
    box-shadow: none !important; }
    .swal2-container .swal2-actions button:focus {
      outline: 0 !important; }

.swal2-icon.swal2-success {
  border-color: transparent !important; }

[class^=swal2] {
  -webkit-tap-highlight-color: inherit !important; }

.builder__content {
  padding: 78px 0; }
  @media only screen and (max-width: 991px) {
    .builder__content .cover-block__info {
      top: 8rem; } }
  .builder__content p {
    color: #000000;
    margin: 0;
    padding: 0; }
  .builder__content a {
    text-decoration: underline;
    color: #000000; }
    .builder__content a:hover {
      color: #FA6400; }
  .builder__content ul {
    color: #000000;
    margin: 0;
    padding: 0;
    list-style-type: disc;
    margin-left: 20px !important;
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important; }
  .builder__content ol {
    list-style-type: decimal;
    margin: 0;
    padding: 0;
    margin-left: 20px !important;
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important; }
  .builder__content b, .builder__content strong {
    font-size: initial;
    line-height: initial; }
  .builder__content i, .builder__content em {
    font-style: italic; }
  .builder__content h1,
  .builder__content .h1 {
    font-size: 80px;
    font-size: 5rem;
    line-height: 96px;
    letter-spacing: -3px;
    color: #000000; }
    @media only screen and (max-width: 991px) {
      .builder__content h1,
      .builder__content .h1 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 46px;
        letter-spacing: 0; } }
  .builder__content h2,
  .builder__content .h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 76px;
    line-height: 92px;
    letter-spacing: -0.75px; }
    @media only screen and (max-width: 991px) {
      .builder__content h2,
      .builder__content .h2 {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 37px;
        letter-spacing: -1.13px; } }
  .builder__content h3,
  .builder__content .h3 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 42px;
    letter-spacing: -0.75px; }
    @media only screen and (max-width: 991px) {
      .builder__content h3,
      .builder__content .h3 {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 24px;
        letter-spacing: 0; } }
  .builder__content h4,
  .builder__content .h4 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 37px; }
    @media only screen and (max-width: 991px) {
      .builder__content h4,
      .builder__content .h4 {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 24px; } }
  .builder__content h5,
  .builder__content .h5 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 29px; }
    @media only screen and (max-width: 991px) {
      .builder__content h5,
      .builder__content .h5 {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 24px; } }
  .builder__content img {
    max-width: 100%;
    height: auto;
    padding: 20px 0; }
  .builder__content .carousel-section__heading {
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 30px; }
  .builder__content .carousel-section__wrap {
    margin-bottom: 30px; }
    .builder__content .carousel-section__wrap .glide__arrow--left {
      border: none;
      padding: 0;
      outline: none;
      box-shadow: none;
      background-image: url("../images/icons/arrow-big-left.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 30px;
      height: 24px;
      font-size: 0;
      left: -2rem;
      position: absolute;
      top: 50%; }
      @media only screen and (max-width: 991px) {
        .builder__content .carousel-section__wrap .glide__arrow--left {
          left: 0; } }
    .builder__content .carousel-section__wrap .glide__arrow--right {
      border: none;
      padding: 0;
      outline: none;
      box-shadow: none;
      background-image: url("../images/icons/arrow-big-right.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 30px;
      height: 24px;
      font-size: 0;
      right: -3rem;
      position: absolute;
      top: 50%; }
      @media only screen and (max-width: 991px) {
        .builder__content .carousel-section__wrap .glide__arrow--right {
          right: 0; } }
    .builder__content .carousel-section__wrap .glide__slide {
      list-style: none; }
      @media (max-width: 991.98px) {
        .builder__content .carousel-section__wrap .glide__slide {
          padding: 30px; } }
    .builder__content .carousel-section__wrap .glide__slides {
      margin: 0 !important; }
    .builder__content .carousel-section__wrap .glide-3 .glide__arrow--right, .builder__content .carousel-section__wrap .glide-3 .glide__arrow--left {
      display: none; }
      @media (max-width: 991.98px) {
        .builder__content .carousel-section__wrap .glide-3 .glide__arrow--right, .builder__content .carousel-section__wrap .glide-3 .glide__arrow--left {
          display: block; } }
    .builder__content .carousel-section__wrap .glide-2 .glide__arrow--right, .builder__content .carousel-section__wrap .glide-2 .glide__arrow--left {
      display: none; }
      @media (max-width: 767.98px) {
        .builder__content .carousel-section__wrap .glide-2 .glide__arrow--right, .builder__content .carousel-section__wrap .glide-2 .glide__arrow--left {
          display: block; } }
    .builder__content .carousel-section__wrap .glide-1 .glide__arrow--right, .builder__content .carousel-section__wrap .glide-1 .glide__arrow--left {
      display: none; }
  .builder__content .carousel-section__item {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 10px; }
  .builder__content .carousel-section__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    padding: 60px 30px;
    text-align: left;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .builder__content .carousel-section__mask {
        padding: 20px; } }
    .builder__content .carousel-section__mask a {
      transition: all 0.4s linear;
      color: #ffffff; }
  .builder__content .carousel-section__cover {
    text-align: center;
    margin: 0 auto 20px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    min-height: 380px;
    max-width: 380px;
    max-height: 380px; }
    .builder__content .carousel-section__cover:hover .carousel-section__mask {
      opacity: 1; }
    .builder__content .carousel-section__cover a {
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
    .builder__content .carousel-section__cover img {
      width: 100%;
      height: 100%; }
  .builder__content.action-block-color__wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 70px;
    text-align: center; }
    @media only screen and (max-width: 991px) {
      .builder__content.action-block-color__wrap {
        padding: 50px 30px; } }
    .builder__content.action-block-color__wrap h1 {
      font-size: 14px;
      color: #FA6400;
      letter-spacing: 0.7px; }
    .builder__content.action-block-color__wrap .btn--orange {
      color: #ffffff;
      text-decoration: none; }
  .builder__content.action-block-image__wrap {
    display: flex;
    flex-wrap: wrap;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px;
    text-align: center; }
    @media only screen and (max-width: 991px) {
      .builder__content.action-block-image__wrap {
        padding: 50px 30px; } }
    .builder__content.action-block-image__wrap h1 {
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0.7px; }
    .builder__content.action-block-image__wrap h3 {
      color: #ffffff; }
    .builder__content.action-block-image__wrap .btn--white {
      color: #000000;
      text-decoration: none; }
      .builder__content.action-block-image__wrap .btn--white:hover:before {
        background-color: #FA6400; }
  .builder__content.photo-gallery__wrap .gallery figure {
    width: 33%; }
    .builder__content.photo-gallery__wrap .gallery figure img {
      max-width: 100%; }
  .builder__content.photo-gallery__wrap .photo-gallery-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .builder__content.photo-gallery__wrap .photo-gallery-block figure {
      display: flex;
      flex-wrap: wrap;
      width: 30%;
      margin: 0 0 30px 0;
      position: relative; }
      @media only screen and (max-width: 768px) {
        .builder__content.photo-gallery__wrap .photo-gallery-block figure {
          margin: 0 0 10px 0;
          width: 47%; } }
      .builder__content.photo-gallery__wrap .photo-gallery-block figure a {
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
      .builder__content.photo-gallery__wrap .photo-gallery-block figure img {
        width: 100%;
        height: auto;
        padding: 0;
        -o-object-fit: cover;
           object-fit: cover; }
      .builder__content.photo-gallery__wrap .photo-gallery-block figure figcaption {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        color: #ffffff;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        transition: all 0.4s linear; }
      .builder__content.photo-gallery__wrap .photo-gallery-block figure:hover {
        cursor: pointer; }
        .builder__content.photo-gallery__wrap .photo-gallery-block figure:hover figcaption {
          opacity: 1;
          transition: all 0.4s linear; }
  .builder__content.photo-gallery__wrap .photo-gallery__heading {
    padding: 70px 0 30px 0;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .builder__content.photo-gallery__wrap .photo-gallery__heading {
        padding: 30px 0 30px 0; } }
  .builder__content.alphabetic-list-block {
    padding: 70px 0; }
    .builder__content.alphabetic-list-block .alphabetic-list-block__list {
      position: relative;
      border-bottom: 1px solid #CACAD4;
      padding-left: 50px; }
    .builder__content.alphabetic-list-block .alphabetic-list-block__letter {
      position: absolute;
      left: 0;
      top: 15px;
      font-size: 18px;
      color: #CACAD4; }
    .builder__content.alphabetic-list-block .alphabetic-list-block__organizations {
      padding: 15px 0;
      font-size: 18px; }
  .builder__content.video-block {
    padding: 70px 0; }
    .builder__content.video-block .video-block__heading {
      padding: 0 0 30px 0;
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: center; }
    .builder__content.video-block .video-block__wrap {
      display: flex;
      flex-wrap: wrap; }
    .builder__content.video-block .video-block__load-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      position: relative; }
    .builder__content.video-block .video-block__button {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      border-radius: 10px;
      height: 30px;
      left: calc(50% - 25px);
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      z-index: 5;
      display: block;
      opacity: 0.7;
      transition: opacity 0.4s linear; }
      @media only screen and (max-width: 991px) {
        .builder__content.video-block .video-block__button {
          left: 50%; } }
      .builder__content.video-block .video-block__button img {
        width: 50px;
        height: 50px;
        padding: 0; }
    .builder__content.video-block .video-block__overlay {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute; }
      .builder__content.video-block .video-block__overlay:hover .video-block__button {
        opacity: 1;
        transition: opacity 0.4s linear; }
    .builder__content.video-block .video-block__caption {
      color: #CACAD4;
      outline: 0 none;
      text-decoration: none;
      transition: color 0.1s cubic-bezier(0, 0, 0.2, 1) 0s;
      float: left;
      max-width: 100%;
      overflow: hidden;
      overflow-wrap: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      padding: 20px 0 0; }
    .builder__content.video-block .video-block__caption-img {
      width: 100%;
      max-width: 100%; }

.pswp--open .pswp__zoom-wrap .pswp__img {
  height: auto !important;
  bottom: 100px;
  top: 0;
  margin: auto 0; }
  @media only screen and (max-width: 991px) {
    .pswp--open .pswp__zoom-wrap .pswp__img {
      bottom: auto; } }

.pswp--open .pswp__bg {
  background-color: rgba(0, 0, 0, 0.5); }

.pswp--open .pswp__preloader--active .pswp__preloader__icn {
  background-image: url("../images/icons/preloader.gif"); }

.pswp--open .pswp__top-bar .pswp__button {
  background-image: url("../images/icons/default-skin.png"); }

@media only screen and (max-width: 768px) {
  .pswp--open .pswp__button--arrow--left,
  .pswp--open .pswp__button--arrow--right {
    top: auto;
    bottom: 40px; } }

.pswp--open .pswp__button--arrow--left:before,
.pswp--open .pswp__button--arrow--right:before {
  top: 22px;
  height: 50px;
  width: 50px; }

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .pswp--open .pswp__top-bar .pswp__button {
    background-image: url("../images/icons/default-skin.svg"); } }

.blog__text i, .civil-society__text i, .blog__text em, .civil-society__text em {
  font-style: italic; }

.blog__text ul, .civil-society__text ul {
  color: #000000;
  margin: 0;
  padding: 0;
  list-style-type: disc;
  margin-left: 20px !important;
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important; }

.blog__text ol, .civil-society__text ol {
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  margin-left: 20px !important;
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important; }

.blog__social, .civil-society__social {
  list-style: none !important; }

.builder-conversation-join,
.builder-about-us__text,
.builder-about-us__people {
  margin: 80px auto 80px; }

.builder-about-us__people b, .builder-about-us__people strong, .builder-about-us__people a {
  font-size: initial !important;
  line-height: initial !important; }

.builder-about-us__people i, .builder-about-us__people em {
  font-style: italic; }

.arrowed a {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 29px;
  font-family: "sofia-pro", sans-serif;
  position: relative;
  display: inline-block;
  letter-spacing: -0.3px;
  font-weight: 400;
  color: #000000; }
  .arrowed a:after {
    content: '';
    background: rgba(250, 100, 0, 0.3);
    height: 11px;
    width: 100%;
    display: block;
    bottom: 4px;
    position: absolute; }
  @media only screen and (max-width: 991px) {
    .arrowed a {
      font-size: 16px;
      font-size: 1rem;
      line-height: 29px; } }
  .arrowed a:before {
    content: '';
    display: inline-block;
    height: 6px;
    width: 6px;
    background: url("/images/icons/icon-map-arrow.svg");
    background-size: cover;
    position: absolute;
    top: 8px;
    right: -7px;
    background-repeat: no-repeat; }

.shoutbox {
  padding: 50px 0;
  background: linear-gradient(180deg, #ffffff 45%, #FCECDD 45%, #FCECDD 100%); }
  .shoutbox__wrap {
    background: #FA6400;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 5% 10%;
    border: 1px solid #FA6400;
    border-radius: 5px;
    position: relative; }
  .shoutbox__present {
    display: flex;
    flex-wrap: wrap; }
    .shoutbox__present .glide__slides {
      transition: none !important; }
  .shoutbox__start-form {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    margin: 20px auto;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600; }
  .shoutbox__slider-item-text {
    margin: 0;
    padding: 0; }
  .shoutbox__slider-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    margin: auto 0;
    transition: opacity 200ms ease-in 200ms; }
    .shoutbox__slider-item.glide__slide--active {
      opacity: 1;
      transition: opacity 200ms ease-in 200ms; }
      .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap {
        line-height: 42px;
        overflow-y: hidden; }
        @media only screen and (max-width: 768px) {
          .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap {
            line-height: normal; } }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap .shoutbox__slider-item-line {
          margin: 0;
          white-space: nowrap;
          color: #ffffff;
          font-size: 30px;
          width: 100%;
          float: left;
          transform: translateY(-50px);
          opacity: 0;
          -webkit-animation-name: shoutboxAppear;
                  animation-name: shoutboxAppear;
          -webkit-animation-timing-function: ease;
                  animation-timing-function: ease;
          -webkit-animation-duration: 3s;
                  animation-duration: 3s;
          -webkit-animation-fill-mode: forwards; }
          @media only screen and (max-width: 991px) {
            .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap .shoutbox__slider-item-line {
              font-size: 24px;
              white-space: unset; } }
          @media only screen and (max-width: 768px) {
            .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap .shoutbox__slider-item-line {
              font-size: 16px;
              white-space: unset; } }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(1) p {
          -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(2) p {
          -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(3) p {
          -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(4) p {
          -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s; }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(5) p {
          -webkit-animation-delay: 1s;
          animation-delay: 1s; }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(6) p {
          -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s; }
        .shoutbox__slider-item.glide__slide--active .shoutbox__slider-item-wrap:nth-child(7) p {
          -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s; }
  .shoutbox__slider-item-name {
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    margin: 20px 0 5px 0; }
  .shoutbox__slider-item-location {
    color: #ffffff;
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 40px 0; }
  .shoutbox__form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    .shoutbox__form .required-fields {
      color: #ffffff;
      font-size: 13px; }
  .shoutbox__form-text {
    font-size: 14px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0 20%; }
    @media only screen and (max-width: 768px) {
      .shoutbox__form-text {
        padding: 0; } }
  .shoutbox__count {
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    right: 20px;
    top: 15px; }
  .shoutbox__align {
    margin: 0 auto; }
  .shoutbox__tell {
    text-transform: uppercase;
    font-size: 14px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px; }
  .shoutbox__text {
    overflow-x: hidden; }
  .shoutbox__text, .shoutbox__name, .shoutbox__location {
    background-color: transparent;
    text-align: center;
    border: none;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    color: white;
    resize: none;
    width: 100%; }
    .shoutbox__text:focus, .shoutbox__name:focus, .shoutbox__location:focus {
      outline: none; }
    .shoutbox__text::-webkit-input-placeholder, .shoutbox__name::-webkit-input-placeholder, .shoutbox__location::-webkit-input-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.3);
      text-align: center; }
    .shoutbox__text::-moz-placeholder, .shoutbox__name::-moz-placeholder, .shoutbox__location::-moz-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.3);
      text-align: center; }
    .shoutbox__text:-ms-input-placeholder, .shoutbox__name:-ms-input-placeholder, .shoutbox__location:-ms-input-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.3);
      text-align: center; }
    .shoutbox__text::-ms-input-placeholder, .shoutbox__name::-ms-input-placeholder, .shoutbox__location::-ms-input-placeholder {
      font-family: "sofia-pro", sans-serif;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.3);
      text-align: center; }
    .shoutbox__text::placeholder, .shoutbox__name::placeholder, .shoutbox__location::placeholder {
      font-family: "sofia-pro", sans-serif;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.3);
      text-align: center; }
      @media only screen and (max-width: 991px) {
        .shoutbox__text::-webkit-input-placeholder, .shoutbox__name::-webkit-input-placeholder, .shoutbox__location::-webkit-input-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text::-moz-placeholder, .shoutbox__name::-moz-placeholder, .shoutbox__location::-moz-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text:-ms-input-placeholder, .shoutbox__name:-ms-input-placeholder, .shoutbox__location:-ms-input-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text::-ms-input-placeholder, .shoutbox__name::-ms-input-placeholder, .shoutbox__location::-ms-input-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text::placeholder, .shoutbox__name::placeholder, .shoutbox__location::placeholder {
          font-size: 30px;
          line-height: 36px; } }
      @media only screen and (max-width: 768px) {
        .shoutbox__text::-webkit-input-placeholder, .shoutbox__name::-webkit-input-placeholder, .shoutbox__location::-webkit-input-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text::-moz-placeholder, .shoutbox__name::-moz-placeholder, .shoutbox__location::-moz-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text:-ms-input-placeholder, .shoutbox__name:-ms-input-placeholder, .shoutbox__location:-ms-input-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text::-ms-input-placeholder, .shoutbox__name::-ms-input-placeholder, .shoutbox__location::-ms-input-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text::placeholder, .shoutbox__name::placeholder, .shoutbox__location::placeholder {
          font-size: 24px;
          line-height: 30px; } }
  .shoutbox__text {
    font-size: 40px;
    line-height: 52px; }
    @media only screen and (max-width: 991px) {
      .shoutbox__text {
        font-size: 30px;
        line-height: 36px; } }
    @media only screen and (max-width: 768px) {
      .shoutbox__text {
        font-size: 24px;
        line-height: 30px; } }
    .shoutbox__text::-webkit-input-placeholder {
      font-size: 40px;
      line-height: 52px; }
    .shoutbox__text::-moz-placeholder {
      font-size: 40px;
      line-height: 52px; }
    .shoutbox__text:-ms-input-placeholder {
      font-size: 40px;
      line-height: 52px; }
    .shoutbox__text::-ms-input-placeholder {
      font-size: 40px;
      line-height: 52px; }
    .shoutbox__text::placeholder {
      font-size: 40px;
      line-height: 52px; }
      @media only screen and (max-width: 991px) {
        .shoutbox__text::-webkit-input-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text::-moz-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text:-ms-input-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text::-ms-input-placeholder {
          font-size: 30px;
          line-height: 36px; }
        .shoutbox__text::placeholder {
          font-size: 30px;
          line-height: 36px; } }
      @media only screen and (max-width: 768px) {
        .shoutbox__text::-webkit-input-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text::-moz-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text:-ms-input-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text::-ms-input-placeholder {
          font-size: 24px;
          line-height: 30px; }
        .shoutbox__text::placeholder {
          font-size: 24px;
          line-height: 30px; } }
  .shoutbox__name {
    font-size: 16px;
    line-height: 18px; }
    .shoutbox__name::-webkit-input-placeholder {
      font-size: 16px;
      line-height: 18px; }
    .shoutbox__name::-moz-placeholder {
      font-size: 16px;
      line-height: 18px; }
    .shoutbox__name:-ms-input-placeholder {
      font-size: 16px;
      line-height: 18px; }
    .shoutbox__name::-ms-input-placeholder {
      font-size: 16px;
      line-height: 18px; }
    .shoutbox__name::placeholder {
      font-size: 16px;
      line-height: 18px; }
  .shoutbox__location {
    font-size: 13px;
    line-height: 16px; }
    .shoutbox__location::-webkit-input-placeholder {
      font-size: 13px;
      line-height: 16px; }
    .shoutbox__location::-moz-placeholder {
      font-size: 13px;
      line-height: 16px; }
    .shoutbox__location:-ms-input-placeholder {
      font-size: 13px;
      line-height: 16px; }
    .shoutbox__location::-ms-input-placeholder {
      font-size: 13px;
      line-height: 16px; }
    .shoutbox__location::placeholder {
      font-size: 13px;
      line-height: 16px; }
  .shoutbox__submit {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    width: 140px;
    margin: 20px auto;
    padding: 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold; }
    .shoutbox__submit:focus {
      outline: none; }
    .shoutbox__submit.disabled {
      color: rgba(0, 0, 0, 0.2); }
  .shoutbox__submitted-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: 140px;
    padding: 20px 30px; }
    @media only screen and (max-width: 991px) {
      .shoutbox__submitted-actions {
        padding: 20px; } }
    @media only screen and (max-width: 768px) {
      .shoutbox__submitted-actions {
        justify-content: center;
        padding: 8px;
        height: 110px; } }
  .shoutbox__submitted-message {
    background-color: transparent;
    text-align: center;
    border: none;
    font-family: "sofia-pro", sans-serif;
    font-size: 40px;
    line-height: 52px;
    font-weight: 500;
    color: white;
    resize: none;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      .shoutbox__submitted-message {
        font-size: 30px;
        line-height: 36px; } }
    @media only screen and (max-width: 768px) {
      .shoutbox__submitted-message {
        font-size: 24px;
        line-height: 30px; } }
  .shoutbox__submitted-name {
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    margin: 20px 0 5px 0; }
  .shoutbox__submitted-location {
    color: #ffffff;
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 100px 0; }
    @media only screen and (max-width: 768px) {
      .shoutbox__submitted-location {
        margin: 0 0 120px 0; } }
  .shoutbox__submitted-thanks {
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .shoutbox__submitted-thanks {
        width: 100%;
        margin-bottom: 10px;
        flex-wrap: wrap; } }
    .shoutbox__submitted-thanks span {
      padding-left: 20px; }
      @media only screen and (max-width: 991px) {
        .shoutbox__submitted-thanks span {
          text-align: left; } }
      @media only screen and (max-width: 768px) {
        .shoutbox__submitted-thanks span {
          padding-left: 10px;
          font-size: 14px; } }
  .shoutbox__submitted-thanks-icon {
    width: 55px;
    height: 55px;
    padding: 15px;
    border: 2px solid #ffffff;
    border-radius: 50%; }
    @media only screen and (max-width: 768px) {
      .shoutbox__submitted-thanks-icon {
        width: 20px;
        height: 20px;
        padding: 3px;
        margin-left: 5px; } }
  .shoutbox__submitted-tweet {
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px 27px;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 20px; }
    @media only screen and (max-width: 768px) {
      .shoutbox__submitted-tweet {
        margin-bottom: 10px;
        width: 45%;
        padding: 5px 15px;
        flex-wrap: nowrap;
        margin-right: 8px; } }
    .shoutbox__submitted-tweet img {
      height: 20px;
      width: 20px;
      margin-right: 10px; }
      @media only screen and (max-width: 768px) {
        .shoutbox__submitted-tweet img {
          width: 10px; } }
    .shoutbox__submitted-tweet span {
      color: #ffffff;
      font-size: 16px;
      line-height: 18px;
      white-space: nowrap; }
      @media only screen and (max-width: 768px) {
        .shoutbox__submitted-tweet span {
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap; } }
  .shoutbox__submitted-more {
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px 27px;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 20px; }
    @media only screen and (max-width: 768px) {
      .shoutbox__submitted-more {
        margin-bottom: 10px;
        width: 45%;
        padding: 5px 15px;
        flex-wrap: nowrap;
        margin-right: 0; } }
    .shoutbox__submitted-more img {
      height: 20px;
      width: 20px;
      margin-right: 10px; }
      @media only screen and (max-width: 768px) {
        .shoutbox__submitted-more img {
          width: 10px; } }
    .shoutbox__submitted-more span {
      color: #ffffff;
      font-size: 16px;
      line-height: 18px;
      white-space: nowrap; }
      @media only screen and (max-width: 768px) {
        .shoutbox__submitted-more span {
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap; } }
  .shoutbox__loading {
    background: #FA6400;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .shoutbox__loading img {
      width: 50px;
      height: 50px; }
  .shoutbox__single-page {
    display: flex;
    justify-content: center; }
    .shoutbox__single-page img {
      margin: 100px auto;
      max-width: 100%; }
  .shoutbox__index-page {
    min-height: 100vh;
    padding: 100px 0 0; }

.image-framed {
  border-radius: 0;
  width: 1200px;
  height: auto;
  padding: 100px 150px; }
  .image-framed .shoutbox__count, .image-framed .shoutbox__submit, .image-framed .required-fields, .image-framed .shoutbox__form-text {
    display: none; }
  .image-framed .shoutbox__text {
    font-size: 40px;
    line-height: 52px; }
  .image-framed .shoutbox__name {
    font-size: 20px;
    line-height: 24px; }
  .image-framed .shoutbox__location {
    font-size: 16px;
    line-height: 18px; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes shoutboxAppear {
  0% {
    transform: translateY(50px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes shoutboxAppear {
  0% {
    transform: translateY(50px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.filters-section {
  background: linear-gradient(180deg, rgba(247, 219, 216, 0.5) 0%, rgba(247, 219, 216, 0.5) 100%);
  padding-top: 178px;
  padding-bottom: 35px; }
  @media only screen and (max-width: 576px) {
    .filters-section {
      padding-top: 97px;
      padding-bottom: 35px; } }
  .filters-section--small {
    padding-top: 85px;
    padding-bottom: 0; }
  .filters-section__header {
    margin-bottom: 70px; }
    @media only screen and (max-width: 576px) {
      .filters-section__header {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px; } }
  .filters-section__title {
    font-size: 60px;
    font-size: 3.75rem;
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 92px;
    text-align: center;
    margin-bottom: 15px; }
    @media only screen and (max-width: 576px) {
      .filters-section__title {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 37px; } }
  .filters-section__subtitle {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 29px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto; }
    @media only screen and (max-width: 576px) {
      .filters-section__subtitle {
        font-size: 16px;
        font-size: 1rem;
        line-height: 20px; } }
  @media only screen and (max-width: 576px) {
    .filters-section__filters {
      display: none; } }
  .filters-section__inspired {
    margin-top: 70px;
    text-align: center; }
    @media only screen and (max-width: 576px) {
      .filters-section__inspired {
        display: none; } }
  .filters-section__inspired-btn {
    border: 0;
    background: none;
    text-align: center; }
    .filters-section__inspired-btn span {
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.7px;
      line-height: 18px;
      text-transform: uppercase; }
    .filters-section__inspired-btn .icon {
      position: relative;
      display: inline-block;
      -webkit-animation: jump-arrow 1s infinite alternate cubic-bezier(0, 0, 0.58, 1);
              animation: jump-arrow 1s infinite alternate cubic-bezier(0, 0, 0.58, 1); }

.filter-form {
  display: flex;
  justify-content: center;
  padding-bottom: 18px; }
  .filter-form--small {
    padding-bottom: 18px; }
  .filter-form__controls {
    background-color: #ffffff;
    color: #CACAD4;
    font-size: 14px;
    font-size: 0.875rem;
    padding-top: 18px;
    padding-bottom: 18px;
    text-align: left; }
  .filter-form__controls-inner {
    display: flex;
    justify-content: center;
    flex-direction: row; }
    @media only screen and (max-width: 576px) {
      .filter-form__controls-inner {
        text-align: center;
        flex-direction: column; } }
  .filter-form__clear {
    color: #FA6400;
    padding: 0;
    margin: 0;
    margin-left: 1.5rem; }
    @media only screen and (max-width: 576px) {
      .filter-form__clear {
        margin-left: 0;
        margin-top: 1rem; } }

.filter-field {
  padding-left: 6px;
  padding-right: 6px; }

.filter-selectbox {
  width: 26.66667%; }

.filter-submit {
  width: 20%; }

@media only screen and (max-width: 991px) {
  .featured-carousel {
    margin-top: 22px;
    padding-left: 15px;
    padding-right: 15px; } }

.featured-carousel__title {
  color: #FA6400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  display: none; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__title {
      display: block; } }

.featured-carousel__card {
  display: flex; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card {
      flex-direction: column; } }

.featured-carousel__card-img {
  flex: 50% 0 0;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card-img {
      flex: 1;
      width: 100%;
      min-height: 270px;
      margin-bottom: 12px; } }

.featured-carousel__card-img-marker {
  font-size: 16px;
  font-size: 1rem;
  background-color: #FA6400;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: .7px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card-img-marker {
      display: none; } }

.featured-carousel__card-text-block {
  width: 50%;
  padding: 125px 120px; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card-text-block {
      padding: 0;
      flex: 1 1 auto;
      width: 100%;
      margin-bottom: 10px; } }

.featured-carousel__card-capture {
  font-size: 14px;
  font-size: 0.875rem;
  height: 18px;
  width: 278px;
  color: #FA6400;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 30px; }
  .featured-carousel__card-capture--count {
    color: #A0A0B4;
    text-transform: lowercase;
    margin-left: 0.5rem; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card-capture {
      display: none; } }

.featured-carousel__card-title {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 61px;
  margin-bottom: 20px;
  font-weight: 600;
  white-space: normal; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card-title {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px; } }

.featured-carousel__card-text {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 29px;
  margin-bottom: 20px;
  white-space: normal; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__card-text {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      margin-bottom: 10px; } }

.featured-carousel__card-cta {
  margin-top: 30px; }

.featured-carousel__bullets {
  display: none; }
  @media only screen and (max-width: 991px) {
    .featured-carousel__bullets {
      display: inline-flex;
      position: relative;
      bottom: 0;
      margin: 1rem 0 2rem; } }

.featured-carousel__arrows .glide__arrow {
  border: 0;
  padding: 0;
  background-image: url("../images/icons/arrow-big-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 45px;
  height: 36px;
  font-size: 0;
  left: 1rem; }
  .featured-carousel__arrows .glide__arrow--right {
    transform: translateY(-50%) rotate(180deg);
    right: 1rem;
    left: auto; }

@media only screen and (max-width: 991px) {
  .featured-carousel__arrows {
    display: none; } }

.featured-carousel__bullet {
  background-color: #A0A0B4; }
  .featured-carousel__bullet.glide__bullet--active {
    background-color: #FA6400; }
    .featured-carousel__bullet.glide__bullet--active:hover {
      border: 0 !important; }

.solution-item {
  padding: 45px 0;
  border-bottom: 1px solid rgba(160, 160, 180, 0.34); }
  .solution-item:first-of-type {
    padding-top: 0; }
  .solution-item:last-of-type {
    border-bottom: 0;
    padding-bottom: 0; }
  @media only screen and (max-width: 576px) {
    .solution-item {
      padding: 30px 0 !important; } }
  .solution-item__meta {
    font-size: 14px;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.4);
    line-height: 29px;
    margin-bottom: 7px;
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 576px) {
      .solution-item__meta {
        line-height: 18px;
        flex-wrap: wrap;
        margin-bottom: 10px; } }
    .solution-item__meta span:nth-of-type(1) {
      flex: 0 0 auto; }
    .solution-item__meta span:nth-of-type(2) {
      margin-left: 1rem;
      margin-right: 1rem; }
      @media only screen and (max-width: 576px) {
        .solution-item__meta span:nth-of-type(2) {
          margin-left: 0.5rem;
          margin-right: 0.5rem; } }
  .solution-item__title {
    font-size: 50px;
    font-size: 3.125rem;
    color: #000;
    display: inline;
    line-height: 61px;
    margin-bottom: 20px;
    font-weight: 600;
    text-decoration: none;
    background-image: linear-gradient(rgba(250, 100, 0, 0.3), rgba(250, 100, 0, 0.3));
    background-position: 0 93%;
    background-repeat: no-repeat;
    background-size: 0 50%;
    transition: background-size .6s; }
    @media only screen and (max-width: 576px) {
      .solution-item__title {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 24px;
        margin-bottom: 10px; } }
    .solution-item__title:hover, .solution-item__title:focus {
      background-size: 100% 50%; }
  .solution-item__excerpt {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 20px;
    font-weight: 400; }
    @media only screen and (max-width: 576px) {
      .solution-item__excerpt {
        font-size: 16px;
        font-size: 1rem;
        line-height: 19px;
        margin-top: 0; } }

.tile-links {
  overflow: hidden; }
  .tile-links__container {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    flex-wrap: wrap; }
  .tile-links__item {
    position: relative;
    flex: 1 0 33.33333%;
    width: 33.33333%;
    padding-left: 20px;
    padding-right: 20px;
    height: 480px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 576px) {
      .tile-links__item {
        flex: 1 0 100%; } }
  .tile-links__item-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .tile-links__item-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 1s ease-out; }
    .tile-links__item-bg::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5); }
    .tile-links__item-inner:hover .tile-links__item-bg {
      transform: scale(1.2); }
  .tile-links__item-link {
    font-size: 30px;
    font-size: 1.875rem;
    position: relative;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 25px;
    font-weight: 600;
    color: #fff; }
    .tile-links__item-link span {
      display: inline-block; }

.inner-content__header {
  position: relative;
  padding-top: 110px;
  margin-bottom: 60px;
  background: linear-gradient(180deg, rgba(247, 219, 216, 0.5) 0%, rgba(254, 241, 222, 0.5) 100%);
  clear: both; }
  @media only screen and (max-width: 576px) {
    .inner-content__header {
      padding-top: 60px;
      padding-bottom: 0;
      margin-bottom: 0; } }
  .inner-content__header--white {
    background: #ffffff;
    padding-bottom: 20px; }

.inner-content__header-title {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 72px;
  text-align: center;
  margin-bottom: 50px; }
  @media only screen and (max-width: 576px) {
    .inner-content__header-title {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 37px;
      margin-bottom: 24px; } }

.inner-content__header-image {
  position: relative;
  line-height: 0;
  height: 760px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 576px) {
    .inner-content__header-image {
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
      height: auto;
      margin-bottom: 0;
      float: none; } }
  .inner-content__header-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center; }
    @media only screen and (max-width: 576px) {
      .inner-content__header-image img {
        width: 100%;
        height: auto;
        -o-object-fit: none;
           object-fit: none;
        -o-object-position: none;
           object-position: none; } }

.inner-content__body {
  padding-left: 25px;
  padding-bottom: 80px; }
  @media only screen and (max-width: 768px) {
    .inner-content__body {
      padding-left: 0; } }

.inner-content__sidebar-separator {
  display: block;
  height: 1px;
  width: 100%;
  background: rgba(160, 160, 180, 0.34);
  margin-top: 30px;
  margin-bottom: 30px; }

.inner-content__properties {
  display: flex; }
  .inner-content__properties--sidebar {
    flex-direction: column; }
  @media only screen and (max-width: 768px) {
    .inner-content__properties {
      flex-direction: column; } }

.inner-content__properties-item {
  flex: 1 0 33.33333%;
  width: 33.33333%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 1rem; }
  @media only screen and (max-width: 768px) {
    .inner-content__properties-item {
      width: 100%;
      padding: 0; } }
  .inner-content__properties--sidebar .inner-content__properties-item {
    width: 100%;
    padding: 0; }
  .inner-content__properties-item:first-child {
    padding-left: 0;
    padding-right: 0; }

.inner-content__properties-item-label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  color: #A0A0B4;
  margin-bottom: 0.5rem; }

.inner-content__properties-item-value {
  font-weight: 600;
  line-height: 19px;
  position: relative; }
  .inner-content__properties-item-value:not(:last-of-type) {
    margin-bottom: 0.5rem; }
  .inner-content__properties-item-value--icon {
    padding-left: 30px; }
    .inner-content__properties-item-value--icon::before {
      content: '';
      display: inline-block;
      background-image: url("../images/icons/icon-check-mark.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0; }

.inner-content__actions .btn:first-child {
  margin-bottom: 0.5rem; }

.inner-content__body-text {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 36px;
  padding: 40px 0; }
  @media only screen and (max-width: 768px) {
    .inner-content__body-text {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 28px; } }
  .inner-content__body-text .read-more {
    cursor: pointer; }
    .inner-content__body-text .read-more:hover {
      text-decoration: underline; }

.inner-content__social {
  display: none; }
  @media only screen and (max-width: 768px) {
    .inner-content__social {
      display: block; } }

.top-panel-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  margin-bottom: 50px; }
  @media only screen and (max-width: 768px) {
    .top-panel-page {
      padding: 0;
      flex-direction: column;
      margin-bottom: 24px; } }
  .top-panel-page__left {
    flex: 1 0 30%;
    width: 30%; }
    @media only screen and (max-width: 768px) {
      .top-panel-page__left {
        width: 100%;
        align-self: flex-start;
        margin-bottom: 40px;
        padding-left: 30px; } }
  .top-panel-page__center {
    flex: 1 0 40%;
    width: 40%;
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 768px) {
      .top-panel-page__center {
        width: 100%; } }
  .top-panel-page__right {
    flex: 1 0 30%;
    width: 30%;
    display: flex;
    justify-content: flex-end; }
    @media only screen and (max-width: 768px) {
      .top-panel-page__right {
        display: none; } }
  .top-panel-page__back {
    font-size: 15px;
    font-size: 0.9375rem;
    color: rgba(160, 160, 180, 0.8);
    display: inline-block;
    position: relative; }
  .top-panel-page__back-icon {
    display: inline-block;
    position: absolute;
    width: 18px;
    top: 3px;
    left: -32px;
    line-height: 0; }
    .top-panel-page__back-icon svg {
      max-width: 100%;
      fill: rgba(160, 160, 180, 0.8); }

.share-widget {
  display: flex;
  align-items: center; }
  .share-widget--mobile {
    display: none; }
    @media only screen and (max-width: 768px) {
      .share-widget--mobile {
        display: flex;
        justify-content: center;
        margin-bottom: 30px; } }
  .share-widget__label {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-right: 30px;
    color: rgba(160, 160, 180, 0.8); }
  .share-widget__list {
    display: flex;
    align-items: center; }
  .share-widget--mobile .share-widget__item {
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .share-widget--mobile .share-widget__item + .share-widget__item {
      margin-left: 10px; }
  .share-widget__item svg {
    fill: #A0A0B4; }
    .share-widget__item svg path {
      fill: #A0A0B4;
      transition: all .3s ease; }
  .share-widget__item + .share-widget__item {
    margin-left: 30px; }
  .share-widget__item:hover path {
    fill: #FA6400; }

.endorse-block__button {
  width: 100%;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 576px) {
    .endorse-block__button {
      margin-bottom: 0.5rem; } }
  .endorse-block__button span {
    display: inline-block;
    position: relative;
    top: -7px; }
  .endorse-block__button svg {
    position: relative;
    top: -4px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    fill: #000; }
    .endorse-block__button svg path {
      fill: #000 !important; }
  .endorse-block__button:not(:disabled):hover svg path {
    fill: #ffffff !important; }

.endorse-block__counter {
  font-size: 14px;
  font-size: 0.875rem;
  color: #A0A0B4;
  margin-bottom: 0.5rem; }
  .endorse-block__counter strong {
    font-size: 14px;
    font-size: 0.875rem; }

.endorse-block__list {
  margin-bottom: 13px; }

.endorse-block__item {
  margin-bottom: 1.25rem; }
  @media only screen and (max-width: 576px) {
    .endorse-block__item {
      margin-bottom: 0.5rem; } }
  .endorse-block__item:last-child {
    margin-bottom: 0; }

.endorse-block__item-name, .endorse-block__item-pos {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600; }

.endorse-block__item-pos {
  color: #A0A0B4; }

.credited-submissions {
  font-size: 14px;
  font-size: 0.875rem; }
  .credited-submissions__header {
    color: #A0A0B4; }
  .credited-submissions__list {
    margin-top: 20px; }
  .credited-submissions__item {
    margin-bottom: 20px;
    line-height: 18px; }
    .credited-submissions__item:last-child {
      margin-bottom: 20px; }
  .credited-submissions__item-name {
    font-weight: bold;
    color: #000;
    transition: .3s ease; }
    .credited-submissions__item-name:hover {
      color: #FA6400; }
  .credited-submissions__item-submission {
    color: #A0A0B4; }

.other-submissions {
  background: #fbfbfc;
  padding: 50px 0; }
  .other-submissions__title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 50px;
    text-transform: uppercase; }
    @media only screen and (max-width: 576px) {
      .other-submissions__title {
        margin-bottom: 0; } }

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99; }
  .modal-backdrop__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1090px; }

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0; }

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease; }

.endorse-modal {
  background: #FA6400;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 60px 10%;
  border: 1px solid #FA6400;
  border-radius: 5px;
  position: relative;
  max-width: 1090px;
  width: 100%; }
  .endorse-modal--success {
    max-width: 650px; }
  .endorse-modal__close {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 30px;
    left: 30px;
    background: none;
    border: 0;
    padding: 0; }
    .endorse-modal__close span {
      position: absolute;
      display: block;
      width: 2px;
      height: 21px;
      background: #ffffff; }
      .endorse-modal__close span:first-child {
        transform: rotate(45deg);
        left: 8px;
        top: -1px; }
      .endorse-modal__close span:last-child {
        transform: rotate(-45deg);
        left: 8px;
        top: -1px; }
  .endorse-modal__icon {
    text-align: center;
    margin-bottom: 18px; }
    .endorse-modal__icon svg {
      width: 45px; }
  .endorse-modal__tell {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center; }
  .endorse-modal__form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 60px; }
  .endorse-modal__input {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 52px;
    background-color: transparent;
    text-align: center;
    border: none;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    color: white;
    resize: none;
    width: 100%; }
    .endorse-modal__input:focus {
      outline: none; }
    @media only screen and (max-width: 991px) {
      .endorse-modal__input {
        font-size: 30px;
        line-height: 36px; } }
    @media only screen and (max-width: 768px) {
      .endorse-modal__input {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 30px; } }
    .endorse-modal__input::-webkit-input-placeholder {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      color: rgba(255, 255, 255, 0.3); }
    .endorse-modal__input::-moz-placeholder {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      color: rgba(255, 255, 255, 0.3); }
    .endorse-modal__input:-ms-input-placeholder {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      color: rgba(255, 255, 255, 0.3); }
    .endorse-modal__input::-ms-input-placeholder {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      color: rgba(255, 255, 255, 0.3); }
    .endorse-modal__input::placeholder {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 52px;
      color: rgba(255, 255, 255, 0.3); }
      @media only screen and (max-width: 991px) {
        .endorse-modal__input::-webkit-input-placeholder {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 36px; }
        .endorse-modal__input::-moz-placeholder {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 36px; }
        .endorse-modal__input:-ms-input-placeholder {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 36px; }
        .endorse-modal__input::-ms-input-placeholder {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 36px; }
        .endorse-modal__input::placeholder {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 36px; } }
      @media only screen and (max-width: 768px) {
        .endorse-modal__input::-webkit-input-placeholder {
          font-size: 24px;
          font-size: 1.5rem;
          line-height: 30px; }
        .endorse-modal__input::-moz-placeholder {
          font-size: 24px;
          font-size: 1.5rem;
          line-height: 30px; }
        .endorse-modal__input:-ms-input-placeholder {
          font-size: 24px;
          font-size: 1.5rem;
          line-height: 30px; }
        .endorse-modal__input::-ms-input-placeholder {
          font-size: 24px;
          font-size: 1.5rem;
          line-height: 30px; }
        .endorse-modal__input::placeholder {
          font-size: 24px;
          font-size: 1.5rem;
          line-height: 30px; } }
  .endorse-modal__submit {
    font-size: 14px;
    font-size: 0.875rem;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    width: 210px;
    margin: 70px auto 0;
    padding: 15px;
    text-transform: uppercase;
    font-weight: bold; }
    .endorse-modal__submit:focus {
      outline: none; }
    .endorse-modal__submit.disabled {
      color: rgba(0, 0, 0, 0.2); }
  .endorse-modal__counter {
    font-size: 14px;
    font-size: 0.875rem;
    opacity: 0.9;
    color: #ffffff;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-top: 18px; }
  .endorse-modal__submitted-message {
    background-color: transparent;
    text-align: center;
    border: none;
    font-family: "sofia-pro", sans-serif;
    font-size: 40px;
    line-height: 52px;
    font-weight: 500;
    color: white;
    resize: none;
    width: 100%;
    margin-bottom: 100px; }
    @media only screen and (max-width: 991px) {
      .endorse-modal__submitted-message {
        font-size: 30px;
        line-height: 36px; } }
    @media only screen and (max-width: 768px) {
      .endorse-modal__submitted-message {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 60px; } }
    .endorse-modal__submitted-message--verify {
      margin-bottom: 40px; }
  .endorse-modal__submitted-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 120px;
    padding: 20px 30px; }
    @media only screen and (max-width: 991px) {
      .endorse-modal__submitted-actions {
        padding: 20px; } }
    @media only screen and (max-width: 768px) {
      .endorse-modal__submitted-actions {
        justify-content: center;
        padding: 8px;
        height: 80px; } }
  .endorse-modal__submitted-thanks {
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 48%; }
    @media only screen and (max-width: 991px) {
      .endorse-modal__submitted-thanks {
        width: 33%;
        flex-wrap: nowrap; } }
    @media only screen and (max-width: 768px) {
      .endorse-modal__submitted-thanks {
        width: 95%; } }
    .endorse-modal__submitted-thanks span {
      padding-left: 20px; }
      @media only screen and (max-width: 991px) {
        .endorse-modal__submitted-thanks span {
          text-align: left; } }
      @media only screen and (max-width: 768px) {
        .endorse-modal__submitted-thanks span {
          padding-left: 10px;
          font-size: 14px; } }
  .endorse-modal__submitted-thanks-icon {
    width: 55px;
    height: 55px;
    padding: 15px;
    border: 2px solid #ffffff;
    border-radius: 50%; }
    @media only screen and (max-width: 768px) {
      .endorse-modal__submitted-thanks-icon {
        width: 20px;
        height: 20px;
        padding: 3px;
        margin-left: 5px; } }
    .endorse-modal__submitted-thanks-icon svg {
      max-width: 100%;
      height: 100%; }

.slideUp-thanks-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in; }

.slideUp-thanks-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.slideUp-thanks-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden; }

.slideUp-thanks-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0; }

.mobile-explore-filter {
  display: none; }
  @media only screen and (max-width: 576px) {
    .mobile-explore-filter {
      margin-bottom: 10px;
      display: block; } }
  .mobile-explore-filter__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 1000;
    overflow-y: scroll; }
  .mobile-explore-filter__overlay-inner {
    padding: 40px 20px 80px; }
  .mobile-explore-filter__title {
    font-size: 14px;
    font-size: 0.875rem;
    color: #FA6400;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    letter-spacing: .7px;
    margin-bottom: 12px; }
  .mobile-explore-filter__sort-group {
    padding: 20px; }
    .mobile-explore-filter__sort-group:not(:last-of-type) {
      border-bottom: 1px solid #CACAD4; }
  .mobile-explore-filter__sort-title {
    font-size: 14px;
    font-size: 0.875rem;
    color: #FA6400;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .7px;
    margin-bottom: 26px; }
  .mobile-explore-filter__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 10px 20px;
    border-top: 1px solid #CACAD4; }
    .mobile-explore-filter__actions > * {
      flex: 0 0 auto; }
  .mobile-explore-filter__cancel {
    color: #CACAD4;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 14px;
    letter-spacing: .7px;
    font-weight: 600;
    text-transform: uppercase; }

.newsletter {
  background: linear-gradient(rgba(247, 219, 216, 0.4), #FCECDD);
  padding: 78px 0; }
  .newsletter__title {
    margin-bottom: 10px; }
  .newsletter__subtitle {
    margin-bottom: 10px; }
  .newsletter__description {
    margin-bottom: 40px; }
  @media only screen and (max-width: 991px) {
    .newsletter {
      padding: 60px 5px; } }
  .newsletter .container {
    max-width: 1040px; }
  @media only screen and (max-width: 991px) {
    .newsletter .col-xs-12 {
      padding: 0; } }
  .newsletter a {
    color: rgba(0, 0, 0, 0.32);
    text-decoration: underline; }
    .newsletter a:hover {
      text-decoration: none; }

.search-component {
  width: 18px;
  height: 18px;
  padding: 0;
  transition: background-color .4s; }
  .search-component svg #search-icon-group {
    fill: #fd7e14;
    transition: fill .4s; }
  .header.fade-in .search-component svg #search-icon-group {
    fill: #000; }
  .search-component:hover svg #search-icon-group {
    fill: #fd7e14; }

.searchForm-enter, .searchForm-leave {
  opacity: 0; }

.searchForm-enter, .searchForm-leave {
  transform: scale(1.1); }

.search-item {
  padding-bottom: 45px;
  border-bottom: 1px solid rgba(160, 160, 180, 0.34); }
  @media only screen and (max-width: 576px) {
    .search-item {
      padding-bottom: 30px; } }
  .search-item__meta {
    font-size: 14px;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.4);
    line-height: 29px;
    margin-bottom: 7px;
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 576px) {
      .search-item__meta {
        line-height: 18px;
        flex-wrap: wrap;
        margin-bottom: 10px; } }
    .search-item__meta span:nth-of-type(1) {
      flex: 0 0 auto; }
    .search-item__meta span:nth-of-type(2) {
      margin-left: 1rem;
      margin-right: 1rem; }
      @media only screen and (max-width: 576px) {
        .search-item__meta span:nth-of-type(2) {
          margin-left: 0.5rem;
          margin-right: 0; } }
    .search-item__meta span:nth-of-type(3) {
      flex: 1 1 auto; }
      @media only screen and (max-width: 576px) {
        .search-item__meta span:nth-of-type(3) {
          flex: 1 1 100%; } }
  .search-item__title {
    font-size: 50px;
    font-size: 3.125rem;
    color: #000;
    display: inline;
    line-height: 61px;
    margin-bottom: 20px;
    font-weight: 600;
    text-decoration: none;
    background-image: linear-gradient(rgba(250, 100, 0, 0.3), rgba(250, 100, 0, 0.3));
    background-position: 0 93%;
    background-repeat: no-repeat;
    background-size: 0 50%;
    transition: background-size .6s; }
    @media only screen and (max-width: 768px) {
      .search-item__title {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 51px; } }
    @media only screen and (max-width: 576px) {
      .search-item__title {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 24px;
        margin-bottom: 10px; } }
    .search-item__title:hover, .search-item__title:focus {
      background-size: 100% 50%;
      color: #000; }
    .search-item__title em {
      background: rgba(253, 126, 20, 0.15); }
  .search-item__excerpt {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 20px;
    font-weight: 400; }
    @media only screen and (max-width: 576px) {
      .search-item__excerpt {
        font-size: 16px;
        font-size: 1rem;
        line-height: 19px;
        margin-top: 0; } }
    .search-item__excerpt em {
      background: rgba(253, 126, 20, 0.15); }

.search-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 85px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  transition: all .5s ease;
  z-index: 99;
  cursor: default;
  padding-top: 110px; }
  @media only screen and (max-width: 991px) {
    .search-modal {
      top: 59px; } }
  @media only screen and (max-width: 576px) {
    .search-modal {
      padding-top: 45px; } }
  .search-modal__container {
    display: block; }
  .search-modal__field {
    display: block;
    position: relative; }
  .search-modal__input {
    font-size: 35px;
    font-size: 2.1875rem;
    width: 100%;
    border-width: 0 0 1px 0;
    border-color: #000;
    padding: 0 25px 20px 0; }
    @media only screen and (max-width: 576px) {
      .search-modal__input {
        font-size: 20px;
        font-size: 1.25rem;
        padding: 0 25px 10px 0; } }
    .search-modal__input:focus, .search-modal__input:active {
      outline: none; }
    .search-modal__input::-webkit-input-placeholder {
      font-size: 35px;
      font-size: 2.1875rem;
      color: rgba(0, 0, 0, 0.2);
      line-height: normal; }
    .search-modal__input::-moz-placeholder {
      font-size: 35px;
      font-size: 2.1875rem;
      color: rgba(0, 0, 0, 0.2);
      line-height: normal; }
    .search-modal__input:-ms-input-placeholder {
      font-size: 35px;
      font-size: 2.1875rem;
      color: rgba(0, 0, 0, 0.2);
      line-height: normal; }
    .search-modal__input::-ms-input-placeholder {
      font-size: 35px;
      font-size: 2.1875rem;
      color: rgba(0, 0, 0, 0.2);
      line-height: normal; }
    .search-modal__input::placeholder {
      font-size: 35px;
      font-size: 2.1875rem;
      color: rgba(0, 0, 0, 0.2);
      line-height: normal; }
      @media only screen and (max-width: 576px) {
        .search-modal__input::-webkit-input-placeholder {
          font-size: 20px;
          font-size: 1.25rem; }
        .search-modal__input::-moz-placeholder {
          font-size: 20px;
          font-size: 1.25rem; }
        .search-modal__input:-ms-input-placeholder {
          font-size: 20px;
          font-size: 1.25rem; }
        .search-modal__input::-ms-input-placeholder {
          font-size: 20px;
          font-size: 1.25rem; }
        .search-modal__input::placeholder {
          font-size: 20px;
          font-size: 1.25rem; } }
  .search-modal__form-reset {
    border: 0;
    background: none;
    padding: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 25px; }
    @media only screen and (max-width: 576px) {
      .search-modal__form-reset {
        top: 10px; } }
    .search-modal__form-reset span {
      position: absolute;
      width: 2px;
      height: 100%;
      background: #fd7e14;
      top: 0;
      left: 8px; }
      .search-modal__form-reset span:nth-child(1) {
        transform: rotate(45deg); }
      .search-modal__form-reset span:nth-child(2) {
        transform: rotate(-45deg); }
  .search-modal__bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 576px) {
      .search-modal__bottom {
        margin-top: 15px; } }
  .search-modal__counter {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px; }
  .search-modal__caption {
    font-size: 14px;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.4);
    line-height: 17px;
    text-align: right;
    display: block;
    justify-self: flex-end;
    margin-left: auto; }
    @media only screen and (max-width: 1260px) {
      .search-modal__caption {
        display: none; } }
  .search-modal__results {
    flex: 1;
    position: relative;
    overflow: auto;
    margin-top: 80px;
    line-height: normal;
    text-align: left; }
    @media only screen and (max-width: 576px) {
      .search-modal__results {
        margin-top: 35px; } }
  .search-modal__results-inner {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding-left: 15px;
    padding-right: 15px; }
  .search-modal__results-category {
    font-size: 14px;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 6px; }
    @media only screen and (max-width: 991px) {
      .search-modal__results-category {
        display: none; } }
  .search-modal__search {
    display: none !important;
    margin-top: 15px;
    width: 100%; }
    @media only screen and (max-width: 1260px) {
      .search-modal__search {
        display: block !important; } }

.intermediary-header {
  padding-top: 170px;
  padding-bottom: 55px; }
  @media only screen and (max-width: 991px) {
    .intermediary-header {
      padding-top: 100px;
      padding-bottom: 30px; } }

.option-card {
  background: linear-gradient(rgba(247, 219, 216, 0.4), #FCECDD);
  padding: 50px 55px;
  margin-bottom: 60px; }
  @media only screen and (max-width: 991px) {
    .option-card {
      margin: 10px;
      padding: 30px 25px; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .option-card {
      width: 32%; } }
  .option-card__icon {
    margin: 40px 0;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .option-card__icon {
        margin: 0 0 23px; } }
    .option-card__icon--modified:after {
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(-30deg);
      left: 38%;
      top: -30%;
      width: 4px;
      height: 21px;
      -webkit-animation: progress 3s linear infinite;
              animation: progress 3s linear infinite;
      border-radius: 2px; }
    .option-card__icon--modified:before {
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(30deg);
      left: 38%;
      bottom: -30%;
      width: 4px;
      height: 21px;
      -webkit-animation: flip 3s linear infinite;
              animation: flip 3s linear infinite;
      border-radius: 2px; }
    .option-card__icon--inner:before {
      left: 49%;
      top: -30%;
      width: 4px;
      height: 21px;
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(0deg);
      -webkit-animation: progress 3s linear infinite;
              animation: progress 3s linear infinite;
      border-radius: 2px; }
    .option-card__icon--inner:after {
      left: 60%;
      top: -27%;
      width: 4px;
      height: 21px;
      transform: rotate(-148deg);
      content: "";
      letter-spacing: 0;
      position: absolute;
      -webkit-animation: progress 3s linear infinite;
              animation: progress 3s linear infinite;
      border-radius: 2px; }
    .option-card__icon--outer:before {
      left: 49%;
      bottom: -30%;
      width: 4px;
      height: 21px;
      content: "";
      letter-spacing: 0;
      position: absolute;
      transform: rotate(0deg);
      -webkit-animation: flip 3s linear infinite;
              animation: flip 3s linear infinite;
      border-radius: 2px; }
    .option-card__icon--outer:after {
      left: 60%;
      bottom: -30%;
      width: 4px;
      height: 21px;
      transform: rotate(148deg);
      content: "";
      letter-spacing: 0;
      position: absolute;
      -webkit-animation: flip 3s linear infinite;
              animation: flip 3s linear infinite;
      border-radius: 2px; }
  .option-card__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 29px;
    font-weight: 700;
    text-transform: uppercase; }
  .option-card__text {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    font-weight: 500; }
    @media only screen and (max-width: 991px) {
      .option-card__text {
        font-size: 16px;
        font-size: 1rem;
        line-height: 19px; } }

.linked-proposals {
  overflow: hidden; }
  .linked-proposals__label {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 17px;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.4);
    position: relative; }
    .linked-proposals__label span {
      font-size: 9px;
      font-size: 0.5625rem;
      display: inline-block;
      width: 14px;
      height: 14px;
      padding: 0;
      text-align: center;
      margin-top: -3px;
      vertical-align: middle;
      margin-left: 7px;
      background-color: #A0A0B4;
      border: 1px solid #A0A0B4;
      border-radius: 50%;
      color: #fff;
      line-height: 12px; }
  .linked-proposals__container {
    border-top: 1px solid #dfdfe5;
    background: #fbfbfc;
    padding-bottom: 30px; }
  .linked-proposals__list {
    display: flex;
    counter-reset: list-count; }
  .linked-proposals__list-item {
    position: relative;
    padding-top: 25px;
    padding-left: 45px;
    padding-right: 15px;
    width: 400px; }
    .linked-proposals__list-item::after {
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      top: -1px;
      left: 0;
      background: #fd7e14; }
    .linked-proposals__list-item.is-active::after {
      width: 100%; }
    .linked-proposals__list-item .item-number {
      font-size: 18px;
      font-size: 1.125rem;
      color: rgba(0, 0, 0, 0.2);
      letter-spacing: 0;
      line-height: 21px;
      position: absolute;
      top: 25px;
      left: 15px; }
  .linked-proposals__list-link {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    color: #000;
    transition: color .3s ease; }
    .linked-proposals__list-item.is-active .linked-proposals__list-link {
      color: #fd7e14; }
    .linked-proposals__list-link:hover {
      color: #fd7e14; }
  .linked-proposals__navs {
    font-size: 14px;
    font-size: 0.875rem;
    color: #A0A0B4;
    font-weight: 600;
    letter-spacing: 0.7px;
    line-height: 18px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px; }
  .linked-proposals__counter {
    line-height: 15px; }
  .linked-proposals__arrow {
    height: 14px;
    width: 18px;
    border: 0;
    background: none;
    padding: 0;
    transition: opacity .2s; }
    .linked-proposals__arrow img {
      width: 100%;
      line-height: 0;
      display: flex;
      align-items: center; }
    .linked-proposals__arrow--left {
      margin-right: 12px; }
    .linked-proposals__arrow--right {
      margin-left: 12px; }
    .linked-proposals__arrow:disabled {
      opacity: 0; }
  .linked-proposals .glide__track,
  .linked-proposals .glide__slides {
    overflow: visible; }

.tag-page__header {
  padding-top: 110px;
  padding-bottom: 30px; }

.video-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 99;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: opacity .3s ease, transform .3s ease, visibility .4s ease; }
  .video-overlay.is-open {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
    transition: opacity .3s ease, transform .3s ease, visibility .1s ease; }
  .video-overlay__header {
    padding: 10px 30px;
    display: flex; }
  .video-overlay__close {
    width: 32px;
    height: 32px;
    background: none;
    border: 0;
    padding: 0;
    margin-left: auto;
    position: relative;
    transition: transform .3s ease; }
    .video-overlay__close:focus {
      outline: none; }
    .video-overlay__close:hover {
      transform: rotate(90deg); }
    .video-overlay__close span {
      display: block;
      height: 1px;
      width: 100%;
      background: #fff;
      position: absolute;
      top: 15px;
      left: 0; }
      .video-overlay__close span:nth-child(1) {
        transform: rotate(45deg); }
      .video-overlay__close span:nth-child(2) {
        transform: rotate(-45deg); }
  .video-overlay iframe {
    width: 100%;
    flex: 1; }

.u-align-left {
  text-align: left; }

.u-align-center {
  text-align: center; }

.u-align-right {
  text-align: right; }

.header {
  height: 85px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 9;
  transition: background 0.2s ease; }
  @media only screen and (max-width: 991px) {
    .header {
      height: 60px; } }
  @media only screen and (max-width: 991px) {
    .header .container {
      padding: 0; } }
  .header .nav__item__lang button {
    color: rgba(0, 0, 0, 0.3);
    padding: 0;
    margin-left: 30px;
    display: flex;
    line-height: normal; }
    .header .nav__item__lang button span {
      text-transform: uppercase; }
    .header .nav__item__lang button .icon {
      margin-top: 9px; }
    .header .nav__item__lang button .icon--arrow--down {
      display: none; }
    .header .nav__item__lang button .icon--arrow--down--black {
      display: block; }
    @media only screen and (max-width: 991px) {
      .header .nav__item__lang button {
        color: rgba(0, 0, 0, 0.3); }
        .header .nav__item__lang button .icon--arrow--down {
          display: none; }
        .header .nav__item__lang button .icon--arrow--down--black {
          display: block; } }
  .header .logo--alt {
    display: none; }
  .header .logo--primary {
    display: block; }
  .header--transparent {
    background: transparent; }
    .header--transparent .nav__item__lang button {
      color: #ffffff; }
      .header--transparent .nav__item__lang button .icon--arrow--down {
        display: block; }
      .header--transparent .nav__item__lang button .icon--arrow--down--black {
        display: none; }
    @media only screen and (max-width: 991px) {
      .header--transparent {
        background: #ffffff;
        height: 60px; } }
    .header--transparent .logo--alt {
      display: block; }
    .header--transparent .logo--primary {
      display: none; }
    .header--transparent a {
      color: #ffffff; }
      .header--transparent a:hover {
        color: #FA6400; }
  .header.fade-in {
    background: #ffffff; }
    .header.fade-in .logo--alt {
      display: none; }
    .header.fade-in .logo--primary {
      display: block; }
    .header.fade-in a {
      color: #000000; }
      .header.fade-in a:hover {
        color: #FA6400; }
      .header.fade-in a.link--orange {
        color: #FA6400; }
    .header.fade-in .btn {
      color: #ffffff; }
      .header.fade-in .btn:hover {
        color: #ffffff; }
    .header.fade-in .nav__item__lang button {
      color: rgba(0, 0, 0, 0.3); }
      .header.fade-in .nav__item__lang button .icon--arrow--down {
        display: none; }
      .header.fade-in .nav__item__lang button .icon--arrow--down--black {
        display: block; }
  .header .nav {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header .nav-toggle {
      background: none;
      border: none;
      width: 21px;
      height: 16px;
      cursor: pointer;
      display: none;
      outline: none;
      position: absolute;
      left: 13px;
      margin-top: -2px;
      padding: 0; }
      @media only screen and (max-width: 991px) {
        .header .nav-toggle {
          display: block; } }
      .header .nav-toggle span {
        position: absolute;
        right: 0;
        top: 7px;
        height: 2px;
        background: #000000;
        width: 100%;
        transition: .3s all ease-out; }
        .header .nav-toggle span:before {
          content: '';
          top: -7px;
          height: 2px;
          background: #000000;
          position: absolute;
          width: 100%;
          right: 0;
          transition: .3s all ease-out; }
        .header .nav-toggle span:after {
          content: '';
          bottom: -7px;
          height: 2px;
          background: #000000;
          position: absolute;
          width: 100%;
          right: 0;
          transition: .3s all ease-out; }
      .header .nav-toggle.menu-open span {
        background: transparent; }
        .header .nav-toggle.menu-open span:after {
          bottom: 0;
          transform: rotate(-45deg); }
        .header .nav-toggle.menu-open span:before {
          top: 0;
          transform: rotate(45deg); }
    .header .nav__logo {
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
      @media only screen and (max-width: 991px) {
        .header .nav__logo {
          display: none; } }
      .header .nav__logo--mobile {
        display: none; }
        @media only screen and (max-width: 991px) {
          .header .nav__logo--mobile {
            display: block; } }
        @media only screen and (max-width: 991px) {
          .header .nav__logo--mobile .logo--mobile {
            max-width: 166px;
            height: 33px;
            position: absolute;
            left: 49px; } }
      .header .nav__logo a {
        display: flex;
        align-items: center;
        height: 44px; }
    .header .nav__list {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0;
      padding: 0; }
      .header .nav__list.menu-opened {
        display: flex;
        width: 100%;
        height: calc(100vh - 60px);
        position: absolute;
        z-index: 99;
        left: 0;
        right: 0;
        top: 52px;
        background: #ffffff;
        padding: 140px 30px 30px; }
        .header .nav__list.menu-opened .nav__item {
          display: block;
          margin: 0; }
      @media only screen and (max-width: 991px) {
        .header .nav__list {
          display: none;
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-end; } }
    .header .nav__item {
      margin: 0 40px 0 0;
      display: inline-block;
      position: relative;
      line-height: 0; }
      .header .nav__item--buttons {
        margin-top: 50px; }
        .header .nav__item--buttons-cover a {
          display: block; }
        .header .nav__item--buttons .footer__social {
          text-align: right; }
          .header .nav__item--buttons .footer__social a {
            margin: 0 2px; }
      @media only screen and (max-width: 991px) {
        .header .nav__item--desktop {
          display: none !important; } }
      .header .nav__item--mobile-item {
        display: none; }
        @media only screen and (max-width: 991px) {
          .header .nav__item--mobile-item {
            display: block !important; } }
      .header .nav__item--mobile {
        display: none; }
        @media only screen and (max-width: 991px) {
          .header .nav__item--mobile {
            display: inline-block;
            position: absolute;
            right: 10px;
            height: 40px;
            padding: 13px 14px; } }
      .header .nav__item--actions {
        margin: 0; }
        @media only screen and (max-width: 991px) {
          .header .nav__item--actions {
            display: none !important; } }
        @media only screen and (max-width: 991px) {
          .header .nav__item--actions.nav__item--mobile-item {
            display: flex !important; } }
      .header .nav__item__lang {
        margin: 0; }
      .header .nav__item > a {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px;
        font-family: "sofia-pro", sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.7px;
        font-weight: 600; }
        @media only screen and (max-width: 991px) {
          .header .nav__item > a {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 52px;
            color: #000000;
            text-transform: capitalize; } }
      .header .nav__item .subnav-list {
        display: none;
        flex-direction: column;
        background: #ffffff;
        border-radius: 4px;
        width: 160px;
        text-align: left;
        transition: all 0.3s ease-in-out;
        padding: 20px 0;
        position: absolute;
        right: 0;
        z-index: 2;
        top: 55px; }
        @media only screen and (max-width: 991px) {
          .header .nav__item .subnav-list {
            top: 30px; } }
        .header .nav__item .subnav-list--about {
          right: auto;
          left: 0;
          width: 300px; }
        @media only screen and (max-width: 576px) {
          .header .nav__item .subnav-list--show-burger {
            background: none;
            display: flex;
            padding: 0;
            position: relative;
            top: 0; } }
        .header .nav__item .subnav-list.menu-opened {
          display: flex; }
        .header .nav__item .subnav-list__link {
          font-size: 16px;
          font-size: 1rem;
          line-height: 30px;
          text-transform: capitalize;
          position: relative;
          display: block;
          padding: 0 30px;
          color: #000000; }
          .header .nav__item .subnav-list__link:hover {
            color: #FA6400; }
          .header .nav__item .subnav-list__link:before {
            content: '';
            display: inline-block;
            border-radius: 100%;
            width: 7px;
            height: 7px;
            position: absolute;
            left: 11px;
            top: 9px; }
          .header .nav__item .subnav-list__link--active:before {
            background: #FA6400; }
      @media only screen and (max-width: 991px) {
        .header .nav__item--search {
          display: none !important; } }
    .header .nav__bottom {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px !important; }

.footer {
  background: #000000;
  padding: 70px 0; }
  @media only screen and (max-width: 768px) {
    .footer {
      padding: 50px 0; } }
  @media only screen and (max-width: 768px) {
    .footer .logo {
      max-width: 166px;
      height: 33px;
      margin: 0 auto; } }
  @media only screen and (max-width: 768px) {
    .footer .logo img {
      width: 100%; } }
  .footer .row {
    align-items: center; }
  .footer__nav {
    justify-content: flex-end;
    display: flex; }
    @media only screen and (max-width: 768px) {
      .footer__nav {
        justify-content: center; } }
    .footer__nav-terms {
      display: flex;
      justify-content: center;
      margin-top: 25px; }
      @media only screen and (max-width: 768px) {
        .footer__nav-terms {
          margin-top: 10px;
          flex-direction: column; } }
      .footer__nav-terms li {
        font-size: 11px;
        font-size: 0.6875rem;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.4);
        margin: 0 10px; }
        @media only screen and (max-width: 768px) {
          .footer__nav-terms li {
            margin: 0 0 10px;
            text-align: center; } }
      .footer__nav-terms a {
        font-size: 11px;
        font-size: 0.6875rem;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.4);
        text-decoration: none; }
        .footer__nav-terms a:hover {
          text-decoration: underline; }
    .footer__nav li {
      margin-right: 30px; }
      .footer__nav li:last-child {
        margin-right: 0; }
    .footer__nav a {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 43px;
      font-family: "sofia-pro", sans-serif;
      font-weight: 600; }
      .footer__nav a:hover {
        color: #FA6400; }
  .footer__social {
    text-align: center; }
    .footer__social a {
      margin-right: 30px; }
      .footer__social a:nth-child(2) {
        margin-right: 0; }

.slider {
  position: relative; }
  .slider:before {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1; }
  .slider__info {
    width: 100%; }
  .slider__wrapper {
    height: 100%;
    position: relative;
    width: 100%;
    overflow: hidden; }
  .slider__slide {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 100%;
    min-height: 920px; }
    @media only screen and (max-width: 768px) {
      .slider__slide {
        min-height: 520px; } }
  .slider__caption {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 17rem; }
    @media only screen and (max-width: 768px) {
      .slider__caption {
        padding-top: 10rem; } }
  .slider__action {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .slider__action--link {
      display: inline-flex;
      align-items: center;
      margin-left: 30px; }
  .slider__scroll {
    text-align: center;
    position: relative;
    z-index: 2;
    margin-top: 10rem; }
    @media only screen and (max-width: 768px) {
      .slider__scroll {
        margin-bottom: 20px;
        margin-top: 6rem; } }
    .slider__scroll a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      letter-spacing: .7px;
      display: inline-block;
      text-align: center;
      font-family: "sofia-pro", sans-serif;
      font-weight: 600; }
    .slider__scroll span {
      display: block;
      margin: 20px auto 0;
      -webkit-animation: bounce 3s infinite;
              animation: bounce 3s infinite; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-8px); }
  60% {
    transform: translateY(-12px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-8px); }
  60% {
    transform: translateY(-12px); } }

.about-us__text p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 29px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  color: #000000; }

.about-us__photo {
  margin-top: -347px;
  text-align: center;
  min-height: 600px;
  height: 100%;
  max-height: 700px;
  margin-bottom: 100px; }
  @media only screen and (max-width: 991px) {
    .about-us__photo {
      max-height: 250px;
      margin-top: 0;
      min-height: 250px;
      margin-bottom: 30px; } }
  .about-us__photo img {
    max-width: 100%;
    height: 100%; }

.about-us__people {
  margin-bottom: 100px; }
  .about-us__people ul.text--medium-big {
    margin-left: 20px; }
    .about-us__people ul.text--medium-big li {
      list-style-type: disc; }
  .about-us__people a {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 29px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    color: #000000;
    text-decoration: underline; }
    .about-us__people a:hover {
      text-decoration: none;
      color: #FA6400; }
  .about-us__people .col-lg-7 p {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 29px;
    font-weight: 400;
    font-family: "sofia-pro", sans-serif;
    color: #000000;
    margin: 0;
    padding: 0; }
  .about-us__people ul, .about-us__people ol {
    font-size: 20px;
    font-size: 1.25rem;
    list-style: initial;
    padding-left: 20px;
    font-family: "sofia-pro", sans-serif; }
    @media only screen and (max-width: 991px) {
      .about-us__people ul, .about-us__people ol {
        font-size: 16px;
        font-size: 1rem;
        line-height: 29px; } }
  .about-us__people ol {
    list-style: decimal; }

.member__mask {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  padding: 60px 30px;
  text-align: left;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.5s ease-in-out; }
  @media only screen and (max-width: 991px) {
    .member__mask {
      padding: 20px; } }
  .member__mask p {
    opacity: 0;
    transform: translateY(250px);
    transition: all 0.4s linear; }

.member__copy {
  padding-bottom: 20px; }

.member__cover {
  text-align: center;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  min-height: 380px;
  max-width: 380px;
  max-height: 380px; }
  .member__cover:hover .member__mask {
    opacity: 1; }
  .member__cover:hover .member__mask p {
    opacity: 1;
    transform: translateY(0px); }
  .member__cover a {
    display: block;
    overflow: hidden; }
  .member__cover img {
    width: 100%;
    height: 100%; }

.member__copy ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px; }
  .member__copy ul li {
    margin: 0 10px; }
    .member__copy ul li span {
      opacity: .7; }
      .member__copy ul li span:hover {
        opacity: 1; }

.member-container {
  max-width: 1200px; }

.wrapper {
  padding: 85px 0 85px; }

.btn {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 14px;
  letter-spacing: .7px;
  height: 48px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  border-radius: 5px;
  padding: 16px 35px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "sofia-pro", sans-serif;
  position: relative;
  z-index: 2;
  overflow: hidden; }
  .btn:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    background-color: #000000;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .5s ease;
    z-index: -1;
    border-radius: 5px; }
  .btn:not(.btn--disabled):hover:before {
    width: 100%; }
  .btn--orange {
    background: #FA6400; }
    .btn--orange:hover {
      color: #ffffff;
      z-index: 2; }
  .btn--secondary {
    background: rgba(160, 160, 180, 0.1);
    color: #000000; }
    .btn--secondary:not(.btn--disabled):hover {
      color: #ffffff;
      z-index: 2; }
  .btn--white {
    background: #ffffff;
    color: #000000; }
    .btn--white:hover {
      color: #ffffff;
      z-index: 2; }
  .btn--large {
    padding: 16px 40px; }
  .btn--white--bordered {
    border: 2px solid #FA6400;
    color: #FA6400 !important;
    text-transform: uppercase; }
    .btn--white--bordered:hover {
      background: #FA6400;
      color: #ffffff !important; }
  .btn--disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .btn--full-width {
    width: 100%; }

.section-separator {
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(160, 160, 180, 0.34); }

.tf-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .tf-label--sidebar {
    margin-top: -60px; }
  @media only screen and (max-width: 576px) {
    .tf-label {
      flex-direction: row;
      align-items: center;
      margin-top: 0; } }
  .tf-label__tf {
    font-size: 14px;
    font-size: 0.875rem;
    height: 25px;
    font-weight: 600;
    letter-spacing: 0.7px;
    line-height: 25px;
    text-align: center;
    border-radius: 4px;
    background: #FA6400;
    color: #fff;
    padding: 0 5px;
    text-transform: uppercase; }
    .tf-label--big .tf-label__tf {
      padding: 0 10px; }
  .tf-label__tab img {
    width: 50px;
    height: auto;
    margin-top: -20px;
    margin-left: 20px; }
  @media only screen and (max-width: 576px) {
    .tf-label__tab img {
      margin-left: 0;
      width: 50px;
      height: auto; } }
  .tf-label__label {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    color: #FA6400;
    margin-bottom: 2px; }
    @media only screen and (max-width: 576px) {
      .tf-label__label {
        flex-direction: row;
        align-items: center;
        margin-left: 10px; } }
    .tf-label--big .tf-label__label {
      text-transform: uppercase; }
    .tf-label__label--icon {
      display: flex;
      flex-direction: column; }
  .tf-label__icon {
    margin-top: 6px; }

.accordion__item {
  position: relative;
  border-bottom: 1px solid rgba(160, 160, 180, 0.34);
  border-left: 0;
  border-right: 0; }
  .accordion__item:first-of-type {
    border-top: 1px solid rgba(160, 160, 180, 0.34); }

.accordion__item-header {
  display: inline-block;
  width: 100%;
  background: none;
  border: 0;
  color: #FA6400;
  padding: 40px 0;
  text-align: left; }
  @media only screen and (max-width: 576px) {
    .accordion__item-header {
      font-size: 14px;
      font-size: 0.875rem;
      padding: 24px 0; } }

.accordion__item-body {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 29px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out; }
  @media only screen and (max-width: 576px) {
    .accordion__item-body {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px; } }

.accordion__item-body-inner {
  padding-bottom: 40px; }

.accordion__item-body-title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 29px; }

.accordion__item-icon {
  position: absolute;
  top: 40px;
  transform: rotate(-90deg);
  right: 0;
  transition: transform .4s ease; }
  @media only screen and (max-width: 576px) {
    .accordion__item-icon {
      top: 24px; } }
  .accordion__item-header.is-active .accordion__item-icon {
    transform: rotate(0deg); }

.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

.loading--trans-bg {
  background: rgba(255, 255, 255, 0.4); }

.loading--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: #fd7e14;
  padding: 0;
  margin: 0; }
  .loading--inner > span {
    display: inline-block;
    background: rgba(253, 126, 20, 0.5);
    border-radius: 50%;
    -webkit-animation: loader-anim 1s infinite ease-in-out;
            animation: loader-anim 1s infinite ease-in-out;
    width: 15px;
    height: 15px;
    margin: 7.5px; }
    .loading--inner > span:nth-of-type(2) {
      -webkit-animation-delay: 0.33333s;
              animation-delay: 0.33333s; }
    .loading--inner > span:nth-of-type(3) {
      -webkit-animation-delay: 0.66667s;
              animation-delay: 0.66667s; }

@-webkit-keyframes loader-anim {
  0%,
  100% {
    opacity: 0;
    transform: scale(1); }
  50% {
    opacity: 0.9;
    transform: scale(1.2); } }

@keyframes loader-anim {
  0%,
  100% {
    opacity: 0;
    transform: scale(1); }
  50% {
    opacity: 0.9;
    transform: scale(1.2); } }

.shame-solution-spacer {
  border-bottom: 1px solid #CACAD4; }
  @media only screen and (max-width: 576px) {
    .shame-solution-spacer {
      padding-top: 50px; } }

.section-spaced {
  padding-top: 1rem;
  padding-bottom: 1rem; }
